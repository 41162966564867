import { FC, useEffect, useState } from 'react'
import '../sensors.css'
import { TypeSensor } from '../core/_models'

type Props = {
  sensor: TypeSensor,
  amount: number,
  isFetching?: boolean,
}

const SensorInfo: FC<Props> = ({sensor, amount, isFetching}) => {
  const [isBlinking, setIsBlinking] = useState<boolean>(false)

  const blink = (milliseconds: number) => {
    setIsBlinking(true)
    setTimeout(() => {
      setIsBlinking(false)
    }, milliseconds)
  }

  useEffect(() => {
    if (isFetching !== undefined) {
      isFetching ? setIsBlinking(true) : blink(5000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching])

  return (
    <div id={sensor.id.toString()} className="m-0 p-2 pt-1 pb-1 border border-dark border-opacity-50 rounded sensor-info">
      <div className="row">
        <div className="col-6">
          <div className="fs-3 fw-bold"><i className={sensor.icon}></i> {amount}</div>
        </div>
        <div className="col-6 text-end">
          {isBlinking && <div className={"animation-blink"}><i className="bi-download"></i></div>}
        </div>
      </div>
      <div className="row">
        <div className="col-12 fs-6 fw-bold">
          {sensor.description}
        </div>
      </div>
    </div>
  )
}

export { SensorInfo }