import { KTSVG } from '../../../_metronic/helpers'

type Props = {
  setShowMeasuringBoxDetailModal: Function,
}
const MeasuringBoxDetailModalHeader = ({setShowMeasuringBoxDetailModal}: Props) => {

  return (
    <div className='modal-header'>
      {/* begin::Modal title */}
      <h2 className='fw-bolder'>Detalhes da Caixa</h2>
      {/* end::Modal title */}

      {/* begin::Close */}
      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-users-modal-action='close'
        onClick={() => setShowMeasuringBoxDetailModal(undefined)}
        style={{cursor: 'pointer'}}
      >
        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
      </div>
      {/* end::Close */}
    </div>
  )
}

export {MeasuringBoxDetailModalHeader}
