import {FC, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {isNotEmpty} from '../../../../../../_metronic/helpers'
import {initialAdministrator, Administrator} from '../core/_models'
import clsx from 'clsx'
import {toast} from 'react-toastify'
import {useListView} from '../core/ListViewProvider'
import {AdministratorsListLoading} from '../components/loading/AdministratorsListLoading'
import {createAdministrator, updateAdministrator} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {useAuth} from '../../../../auth'

type Props = {
  isAdministratorLoading: boolean
  response: any
  status: any
}

const addAdministratorSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Mínimo 3 caracteres')
    .max(100, 'Maximum 50 caracteres')
    .required('Nome do administrador é obrigatório'),
  email: Yup.string()
    .email('Email inválido')
    .min(3, 'Mínimo 3 caracteres')
    .max(50, 'Máximo 50 caracteres')
    .required('Email é obrigatório'),
  document_number: Yup.string()
    .min(3, 'Mínimo 3 caracteres')
    .max(50, 'Maximum 50 caracteres')
    .required('Documento é obrigatório'),
  password: Yup.string()
    .min(6, 'Mínimo 6 caracteres')
    .max(50, 'Máximo 50 caracteres')
    .required('Senha é obrigatória'),
})

const editAdministratorSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Mínimo 3 caracteres')
    .max(100, 'Maximum 50 caracteres')
    .required('Nome do administrador é obrigatório'),
  email: Yup.string()
    .email('Email inválido')
    .min(3, 'Mínimo 3 caracteres')
    .max(50, 'Máximo 50 caracteres')
    .required('Email é obrigatório'),
  document_number: Yup.string()
    .min(3, 'Mínimo 3 caracteres')
    .max(50, 'Maximum 50 caracteres')
    .required('Documento é obrigatório'),
})

const AdministratorEditModalForm: FC<Props> = ({response, isAdministratorLoading}) => {
  const administrator = response.length > 0 ? response[0] : response
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()

  const {currentUser} = useAuth()
  const companyId = (currentUser?.last_company_id) ? +currentUser.last_company_id : 0;

  const [administratorForEdit] = useState<Administrator>({
    ...administrator,
    name: administrator.name || initialAdministrator.name,
    status_id: administrator.status_id || initialAdministrator.status_id,
    email: administrator.email || initialAdministrator.email,
    document_number: administrator.document_number || initialAdministrator.document_number,
    company_id: companyId,
    can_manager_tests_feature: administrator.can_manager_tests_feature || initialAdministrator.can_manager_tests_feature
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: administratorForEdit,
    validationSchema: itemIdForUpdate ? editAdministratorSchema : addAdministratorSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          await updateAdministrator(values)
          toast.success(`Administrador ${values.name} atualizado com sucesso`)
        } else {
          await createAdministrator(values)
          toast.success(`Administrador ${values.name} criado com sucesso`)
        }
      } catch (ex: any) {
        ex.response.data.message ? toast.warning(ex.response.data.message) : toast.error(ex.message)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y ps-1'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Administrador</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Nome do Administrador'
              {...formik.getFieldProps('name')}
              type='text'
              name='name'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.name && formik.errors.name},
                {
                  'is-valid': formik.touched.name && !formik.errors.name,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isAdministratorLoading}
            />
            {formik.touched.name && formik.errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.name}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          {/* end::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>E-Mail</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='E-mail'
              {...formik.getFieldProps('email')}
              type='text'
              name='email'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.email && formik.errors.email},
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isAdministratorLoading}
            />
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.email}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Documento</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Documento'
              {...formik.getFieldProps('document_number')}
              type='text'
              name='document_number'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.document_number && formik.errors.document_number},
                {
                  'is-valid': formik.touched.document_number && !formik.errors.document_number,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isAdministratorLoading}
            />
            {formik.touched.document_number && formik.errors.document_number && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.document_number}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          {!itemIdForUpdate && <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Senha</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Senha'
              {...formik.getFieldProps('password')}
              type='text'
              name='password'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.password && formik.errors.password},
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isAdministratorLoading}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>}
          <div className='row mb-7 m-0 p-0'>
            <div className='col-sm-3 ps-0'>
              <label className='fw-bold fs-6 mb-2'>Status</label>
              <select
                className='form-control form-control-solid form-select mb-3 mb-lg-0'
                {...formik.getFieldProps('status_id')}
                name='status_id'
                disabled={formik.isSubmitting || isAdministratorLoading}
              >
                <option disabled>
                  Selecione o status
                </option>
                <option value={'3'}>Ativo</option>
                <option value={'4'}>Bloqueado</option>
                <option value={'5'}>Inativo</option>
              </select>
              {formik.touched.status_id && formik.errors.status_id && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{formik.errors.status_id}</span>
                </div>
              )}
            </div>
            <div className='col-sm-4 ps-0'>
              {itemIdForUpdate && <>
              <label className='fw-bold fs-6 mb-2'>Função</label>
              <select
                className='form-control form-control-solid form-select mb-3 mb-lg-0'
                {...formik.getFieldProps('role_id')}
                name='role_id'
                disabled={formik.isSubmitting || isAdministratorLoading}
              >
                <option disabled>
                  Selecione a função
                </option>
                <option value={'1'}>Operador</option>
                <option value={'2'}>Coordenador</option>
                <option value={'3'}>Gerente</option>
                <option value={'4'}>Administrador</option>
              </select>
              {formik.touched.role_id && formik.errors.role_id && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{formik.errors.role_id}</span>
                </div>
              )}
              </>}
            </div>
            <div className='d-flex col-sm-2 align-items-center justify-content-end'>
              {/* begin::Input */}
              <div className='form-check form-check-solid form-check-custom form-switch mt-8'>
                {/* begin::Label */}
                <label className='fw-bold fs-6 me-2'>Novo:</label>
                {/* end::Label */}
                <input
                  {...formik.getFieldProps('is_new_user')}
                  type='checkbox'
                  name='is_new_user'
                  checked={formik.getFieldProps('is_new_user').value}
                  className={clsx(
                    'form-control form-check-input mb-lg-0',
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isAdministratorLoading}
                />
              </div>
              {formik.touched.is_new_user && formik.errors.is_new_user && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.is_new_user}</span>
                  </div>
                </div>
              )}
              {/* end::Input */}
            </div>
            <div className='d-flex col-sm-3 ps-0 pe-0 align-items-center justify-content-end'>
              {/* begin::Input */}
              <div className='form-check form-check-solid form-check-custom form-switch mt-8'>
                {/* begin::Label */}
                <label className='fw-bold fs-6 me-2'>Permitir Testes:</label>
                {/* end::Label */}
                <input
                  {...formik.getFieldProps('can_manager_tests_feature')}
                  type='checkbox'
                  name='can_manager_tests_feature'
                  checked={formik.getFieldProps('can_manager_tests_feature').value}
                  className={clsx(
                    'form-control form-check-input mb-lg-0',
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isAdministratorLoading}
                />
              </div>
              {formik.touched.can_manager_tests_feature && formik.errors.can_manager_tests_feature && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.can_manager_tests_feature}</span>
                  </div>
                </div>
              )}
              {/* end::Input */}
            </div>
          </div>
        </div>
        {/* end::Scroll */}
              
        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isAdministratorLoading}
          >
            Cancelar
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={isAdministratorLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Confirmar</span>
            {(formik.isSubmitting || isAdministratorLoading) && (
              <span className='indicator-progress'>
                Por favor, aguarde...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isAdministratorLoading) && <AdministratorsListLoading />}
    </>
  )
}

export {AdministratorEditModalForm}
