import {useEffect, useState} from 'react'
import { useQuery } from 'react-query'
import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
import {initialQueryState, KTSVG, QUERIES} from '../../../../../../../_metronic/helpers'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {MeasuringBoxStatus, Project} from '../../core/_models'
import {getAllProjects} from '../../core/_requests'
import {useAuth} from '../../../../../auth'

const ListFilter = () => {
  const {updateState} = useQueryRequest()
  const {isLoading} = useQueryResponse()
  const [status, setStatus] = useState<string>()
  const [bluetooth, setBluetooth] = useState<string>()
  const [cmb_number, setCmbNumber] = useState<string>()
  const [project_name, setProjectName] = useState<string>()
  const {currentUser} = useAuth()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const resetData = () => {
    updateState({filter: undefined, ...initialQueryState})
    setStatus('')
    setBluetooth('')
    setCmbNumber('')
    setProjectName('')
  }

  const filterData = () => {
    updateState({
      filter: {status_id: status, bluetooth, cmb_number, project_name},
      ...initialQueryState,
    })
  }

  const {data: projects} = useQuery(
    `${QUERIES.PROJECTS}`,
    () => {
      const companyId = (currentUser?.last_company_id) ? +currentUser.last_company_id : 0;
      return getAllProjects(companyId);
    },
    {
      enabled: true,
      refetchOnWindowFocus: false,
      onError: (err) => {
        console.error(err)
      },
    }
  )

  return (
    <>
      {/* begin::Filter Button */}
      <button
        disabled={isLoading}
        type='button'
        className='btn btn-light-primary me-3'
        data-kt-menu-trigger='click'
        
      >
        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
        Filtrar
      </button>
      {/* end::Filter Button */}
      {/* begin::SubMenu */}
      <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
        {/* begin::Header */}
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Opções de Filtro</div>
        </div>
        {/* end::Header */}

        {/* begin::Separator */}
        <div className='separator border-gray-200'></div>
        {/* end::Separator */}

        {/* begin::Content */}
        <div className='px-7 py-5' data-kt-user-table-filter='form'>
          <div className='mb-6'>
            <label className='form-label fs-6 fw-bold col-lg-8'>Bluetooth:</label>
            <input
                type='text'
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                placeholder='Bluetooth'
                value={bluetooth}
                onChange={(e) => setBluetooth(e.currentTarget.value)}
              />
          </div>

          <div className='mb-6'>
            <label className='form-label fs-6 fw-bold col-lg-8'>Número CMB:</label>
            <input
                type='text'
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                placeholder='Número CMB'
                value={cmb_number}
                onChange={(e) => setCmbNumber(e.currentTarget.value)}
              />
          </div>

          <div className='mb-6'>
            <label className='form-label fs-6 fw-bold col-lg-8'>Projeto:</label>
            <select
              className='form-select form-select-solid'
              name='project_id'
              value={project_name}
              onChange={(e) => setProjectName(e.target.value)}
              defaultValue=""
            >
              <option key={-1} value="" disabled>
                Selecione o projeto
              </option>
              {projects &&
                (projects as Project[]).map((project, index) => {
                  return <option key={index} value={`${project.name}`}>{project.name}</option>
                })}
            </select>
          </div>
          
          {/* begin::Input group */}
          <div className='mb-6'>
            <label className='form-label fs-6 fw-bold'>Status:</label>
            <select
              className='form-select form-select-solid'
              data-kt-select2='true'
              data-placeholder='Selecione o status'
              data-allow-clear='true'
              data-kt-course-table-filter='level_id'
              data-hide-search='true'
              onChange={(e) => setStatus(e.target.value)}
              value={status}
              defaultValue=""
            >
              <option key={-1} value="" disabled>
                Selecione o status
              </option>
              {Object.values(MeasuringBoxStatus).map((status) => {
                return <option key={status.id} value={`${status.id}`}>{status.name}</option>
              })}
            </select>
          </div>
          {/* end::Input group */}

          {/* begin::Actions */}
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              disabled={isLoading}
              onClick={resetData}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            >
              Redefinir
            </button>
            <button
              disabled={isLoading}
              type='button'
              onClick={filterData}
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
            >
              Aplicar
            </button>
          </div>
          {/* end::Actions */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::SubMenu */}
    </>
  )
}

export {ListFilter}
