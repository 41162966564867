import {useEffect, useState} from 'react'
import clsx from 'clsx'
import {KTSVG, avatarPath} from '../../../helpers'
import {HeaderUserMenu} from '../../../partials'
import {useLayout} from '../../core'
import {useAuth} from '../../../../app/modules/auth'
import { CurrentUserCompany } from './CurrentUserCompany'

const itemClass = 'ms-1 ms-lg-3'
const userAvatarClass = 'symbol-35px symbol-md-40px'
const btnIconClass = 'svg-icon-1'

const Navbar = () => {
  const {currentUser} = useAuth()
  const {config} = useLayout()
  const [avatar, setAvatar] = useState('')

  useEffect(() => {
    setAvatar(avatarPath(currentUser?.avatar_url, currentUser?.avatar))
  }, [currentUser])

  return (
    <div className='d-flex app-navbar flex-shrink-0 align-items-center'>
      <CurrentUserCompany />
      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <img src={avatar} alt={currentUser?.name} />
        </div>
        <HeaderUserMenu />
      </div>

      {config.app?.header?.default?.menu?.display_mobile && (
        <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-35px h-35px'
            id='kt_app_header_menu_toggle'
          >
            <KTSVG path='/media/icons/duotune/text/txt001.svg' className={btnIconClass} />
          </div>
        </div>
      )}
    </div>
  )
}

export {Navbar}
