import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {Map} from '../pages/map/Map'
import {SensorsPage} from '../pages/sensors/SensorsPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import {Menus, isAccessGranted} from '../../app/helpers/MenuAccessControl'

const PrivateRoutes = () => {
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const CompanyPage = lazy(() => import('../modules/apps/companies-management/CompanyPage'))
  const PageProjects = lazy(() => import('../modules/apps/projects-management/PageProjects'))
  const OperatorsPage = lazy(() => import('../modules/apps/operators-management/OperatorsPage'))
  const AdministratorsPage = lazy(() => import('../modules/apps/administrators-management/AdministratorsPage'))
  const ManagersPage = lazy(() => import('../modules/apps/managers-management/ManagersPage'))
  const CoordinatorsPage = lazy(() => import('../modules/apps/coordinators-management/CoordinatorsPage'))
  const Page = lazy(() => import('../modules/apps/measuring-box-management/Page'))
  const PageLogs = lazy(() => import('../modules/apps/logs-management/PageLogs'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registration */}
        {(isAccessGranted(Menus.dashboard.id).read || isAccessGranted(Menus.dashboard.id).write) &&
          <>
          <Route path='auth/*' element={<Navigate to='/dashboard' />} />
          <Route path='dashboard' element={<DashboardWrapper />} />
          </>
        }
        {/* Pages */}
        {(isAccessGranted(Menus.dashboard.id).read || isAccessGranted(Menus.dashboard.id).write) &&
          <Route path='dashboard' element={<DashboardWrapper />} />
        }
        {(isAccessGranted(Menus.map.id).read || isAccessGranted(Menus.map.id).write) &&
          <Route path='map' element={<Map />} />
        }
        {(isAccessGranted(Menus.sensors.id).read || isAccessGranted(Menus.sensors.id).write) &&
          <Route path='sensors' element={<SensorsPage />} />
        }
        {/* Lazy Modules */}
        {(isAccessGranted(Menus.companies.id).read || isAccessGranted(Menus.companies.id).write) &&
          <Route
            path='apps/companies-management/*'
            element={
              <SuspensedView>
                <CompanyPage />
              </SuspensedView>
            }
          />
        }
        {(isAccessGranted(Menus.projects.id).read || isAccessGranted(Menus.projects.id).write) &&
          <Route
            path='apps/projects-management/*'
            element={
              <SuspensedView>
                <PageProjects />
              </SuspensedView>
            }
          />
        }
        {(isAccessGranted(Menus.operators.id).read || isAccessGranted(Menus.operators.id).write) &&
          <Route
            path='apps/operators-management/*'
            element={
              <SuspensedView>
                <OperatorsPage />
              </SuspensedView>
            }
          />
        }
        {(isAccessGranted(Menus.administrators.id).read || isAccessGranted(Menus.administrators.id).write) &&
          <Route
            path='apps/administrators-management/*'
            element={
              <SuspensedView>
                <AdministratorsPage />
              </SuspensedView>
            }
          />
        }
        {(isAccessGranted(Menus.managers.id).read || isAccessGranted(Menus.managers.id).write) &&
          <Route
            path='apps/managers-management/*'
            element={
              <SuspensedView>
                <ManagersPage />
              </SuspensedView>
            }
          />
        }
        {(isAccessGranted(Menus.coordinators.id).read || isAccessGranted(Menus.coordinators.id).write) &&
          <Route
            path='apps/coordinators-management/*'
            element={
              <SuspensedView>
                <CoordinatorsPage />
              </SuspensedView>
            }
          />
        }
        {(isAccessGranted(Menus.measuring_boxes.id).read || isAccessGranted(Menus.measuring_boxes.id).write) &&
          <Route
            path='apps/measuring-box-management/*'
            element={
              <SuspensedView>
                <Page />
              </SuspensedView>
            }
          />
        }
        {(isAccessGranted(Menus.logs.id).read || isAccessGranted(Menus.logs.id).write) &&
          <Route
            path='apps/logs-management/*'
            element={
              <SuspensedView>
                <PageLogs />
              </SuspensedView>
            }
          />
        }

        <Route
          path='cmb/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />

        {/* Privacy Policy */}
        <Route path='*' element={<Navigate to='/error/privacy-policy' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
