import axios, {AxiosResponse} from 'axios'
import {
  MeasuringBoxesWarningsQueryResponse,
  TypeResponseSuccess,
} from './_models'
import { ID, Response } from '../../../../_metronic/helpers'

const API_URL = process.env.REACT_APP_API_URL

const getOpeningRequestsFilter = (query: Record<string, any>): Promise<MeasuringBoxesWarningsQueryResponse> => {
  return axios
    .get(`${API_URL}/log-box/nastek/opening-requests/filter`, {params: query})
    .then((d: AxiosResponse<MeasuringBoxesWarningsQueryResponse>) => d.data)
}

const openDoorRelease = (measuring_box_id: ID): Promise<TypeResponseSuccess | undefined> => {
  return axios({
    method: 'post',
    url: `${API_URL}/log-box/nastek/open-door-released/${measuring_box_id}`,
    headers: {
      secret_key: 'X5VRrF&6(@!45dFN{xZ'
    }
  })
  .then((response: Response<TypeResponseSuccess>) => response.data)
}

const openDoorDenied = (measuring_box_id: ID): Promise<TypeResponseSuccess | undefined> => {
  return axios
    .post(`${API_URL}/log-box/nastek/open-door-denied/${measuring_box_id}`, {}, {headers: {secret_key: 'X5VRrF&6(@!45dFN{xZ'}})
    .then((response: Response<TypeResponseSuccess>) => response.data)
}

const getAlarmMaximumTimeOpenDoorFilter = (query: Record<string, any>): Promise<MeasuringBoxesWarningsQueryResponse> => {
  return axios
    .get(`${API_URL}/log-box/nastek/alarm-maximum-time-open-door/filter`, {params: query})
    .then((d: AxiosResponse<MeasuringBoxesWarningsQueryResponse>) => d.data)
}

const getAlarmViolationFilter = (query: Record<string, any>): Promise<MeasuringBoxesWarningsQueryResponse> => {
  return axios
    .get(`${API_URL}/log-box/nastek/alarm-violation/filter`, {params: query})
    .then((d: AxiosResponse<MeasuringBoxesWarningsQueryResponse>) => d.data)
}

const getAlarmVibrationFilter = (query: Record<string, any>): Promise<MeasuringBoxesWarningsQueryResponse> => {
  return axios
    .get(`${API_URL}/log-box/nastek/alarm-vibration/filter`, {params: query})
    .then((d: AxiosResponse<MeasuringBoxesWarningsQueryResponse>) => d.data)
}

const getAlarmSoundFilter = (query: Record<string, any>): Promise<MeasuringBoxesWarningsQueryResponse> => {
  return axios
    .get(`${API_URL}/log-box/nastek/alarm-sound/filter`, {params: query})
    .then((d: AxiosResponse<MeasuringBoxesWarningsQueryResponse>) => d.data)
}

const getAlarmEnergyFilter = (query: Record<string, any>): Promise<MeasuringBoxesWarningsQueryResponse> => {
  return axios
    .get(`${API_URL}/log-box/nastek/alarm-energy/filter`, {params: query})
    .then((d: AxiosResponse<MeasuringBoxesWarningsQueryResponse>) => d.data)
}

const getAlarmCutCableFilter = (query: Record<string, any>): Promise<MeasuringBoxesWarningsQueryResponse> => {
  return axios
    .get(`${API_URL}/log-box/nastek/alarm-cut-cable/filter`, {params: query})
    .then((d: AxiosResponse<MeasuringBoxesWarningsQueryResponse>) => d.data)
}

export {
  getOpeningRequestsFilter,
  openDoorRelease,
  openDoorDenied,
  getAlarmMaximumTimeOpenDoorFilter,
  getAlarmViolationFilter,
  getAlarmVibrationFilter,
  getAlarmSoundFilter,
  getAlarmEnergyFilter,
  getAlarmCutCableFilter,
}
