import { FC } from 'react'
import moment from 'moment'
import { LedRed } from '../../../components/Leds'
import { MeasuringBoxesWarnings } from '../core/_models';

type Props = {
  box: MeasuringBoxesWarnings;
  refetchOpeningRequests: () => void;
}

const MeasuringBoxDefault: FC<Props> = ({box, refetchOpeningRequests}) => {
  return (
    <div className="border-dark border-opacity-50 bg-opacity-10 border p-1 pt-0 rounded" 
      style={{ width: "160px" }}
      >
      <div id='project_name' title={`Projeto: ${box.project_name}`} className='text-truncate' >
        <span className="fs-7 fw-bold"></span>
        <span className="fs-6 fw-bold">{box.project_name}</span>
      </div>
      <div id='nro_cmb'>
        <span className="fs-7 fw-bold">Nº CMB: </span>
        <span className="fs-6 fw-bold">{box.cmb_number}</span>
      </div>
      <div id='bluetooth' title='Número Bluetooth'>
        <span className="fs-7 fw-bold"><i className="bi-bluetooth"></i> </span>
        <span className="fs-6 fw-bold">{box.bluetooth}</span>
      </div>
      <div id='request_from_user' title={`Usuário Solicitante: ${box.user_name}`} className='text-truncate' >
        <span className="fs-7 fw-bold"><i className="bi-person-circle"></i> </span>
        <span className="fs-6 fw-bold">{box.user_name}</span>
      </div>
      <div id='date_alarm' title={`Data e Hora do Alarme`} className='text-truncate' >
        <span className="fs-7 fw-bold"><i className="bi-alarm"></i> </span>
        <span className="fs-6 fw-bold">{moment(box.created_at).format('DD/MM/YY HH:mm:ss')}</span>
      </div>

      <div className="border-bottom border-dark border-opacity-50"></div>

      <div id="leds" className="d-flex justify-content-end gap-3 me-1">
        <div className="animation-blink" style={{height: "17px"}}><LedRed /></div>
      </div>

    </div>
  );
}

export {MeasuringBoxDefault}