import { FC, useEffect, useState } from "react"
import { SensorInfo } from "./SensorInfo"
import { MeasuringBoxesWarningsQueryResponse, SensorTypes, MeasuringBoxesWarnings, TypeSensor } from "../core/_models"
import clsx from "clsx"
import "../sensors.css"
import { MeasuringBoxOpeningRequests } from "./MeasuringBoxOpeningRequests"
import { useQuery } from "react-query"
import moment from "moment"
import {
  getAlarmCutCableFilter,
  getAlarmEnergyFilter,
  getAlarmMaximumTimeOpenDoorFilter,
  getAlarmSoundFilter,
  getAlarmVibrationFilter,
  getAlarmViolationFilter,
  getOpeningRequestsFilter,
} from "../core/_requests"
import { useAuth } from "../../../modules/auth"
import { KTSVG } from "../../../../_metronic/helpers"
import { MeasuringBoxDefault } from "./MeasuringBoxDefault"

const Sensors: FC = () => {
  const {currentUser} = useAuth()
  const [selectedSensor, setSelectedSensor] = useState<TypeSensor>()
  const [boxes, setBoxes] = useState<Array<MeasuringBoxesWarnings>>([])

  const secondsToRefresh = 60 * 5
  const [refetchingSeconds, setRefetchingSeconds] = useState(secondsToRefresh)

  const timer = async () => {
    setTimeout(async () => {
      if (refetchingSeconds === 0) {
        await refetchOpeningRequests()
        await refetchAlarmMaximumTimeOpenDoorFilter()
        await refetchAlarmViolationFilter()
        await refetchAlarmVibrationFilter()
        await refetchAlarmSoundFilter()
        await refetchAlarmEnergyFilter()
        await refetchAlarmCutCableFilter()
        setRefetchingSeconds(secondsToRefresh)
        return
      }
      setRefetchingSeconds(refetchingSeconds - 1)
    }, 1000)
  }

  const {
    isFetching: isFetchingOpeningRequests,
    refetch: refetchOpeningRequests,
    data: openingRequests,
  } = useQuery(
    'OPENING_REQUESTS_QUERY',
    async () => {
      const response: MeasuringBoxesWarningsQueryResponse = await getOpeningRequestsFilter(
        {
          company_id: currentUser?.last_company_id, 
          take: 0, skip: 500,
        }
      )
      const openingRequests = response?.data as MeasuringBoxesWarnings[]

      return openingRequests
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const {
    isFetching: isFetchingAlarmMaximumTimeOpenDoorFilter,
    refetch: refetchAlarmMaximumTimeOpenDoorFilter,
    data: alarmMaximumTimeOpenDoorFilter,
  } = useQuery(
    'ALARM-MAXIMUM-TIME-OPEN-DOOR-FILTER',
    async () => {
      const response: MeasuringBoxesWarningsQueryResponse = await getAlarmMaximumTimeOpenDoorFilter(
        {
          company_id: currentUser?.last_company_id, 
          take: 0, skip: 500,
        }
      )
      const alarmMaximumTimeOpenDoorFilter = response?.data as MeasuringBoxesWarnings[]

      return alarmMaximumTimeOpenDoorFilter
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const {
    isFetching: isFetchingAlarmViolationFilter,
    refetch: refetchAlarmViolationFilter,
    data: alarmViolationFilter,
  } = useQuery(
    'ALARM-VIOLATION-FILTER',
    async () => {
      const response: MeasuringBoxesWarningsQueryResponse = await getAlarmViolationFilter(
        {
          company_id: currentUser?.last_company_id, 
          take: 0, skip: 500,
        }
      )
      const alarmViolationFilter = response?.data as MeasuringBoxesWarnings[]

      return alarmViolationFilter
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const {
    isFetching: isFetchingAlarmVibrationFilter,
    refetch: refetchAlarmVibrationFilter,
    data: alarmVibrationFilter,
  } = useQuery(
    'ALARM-VIBRATION-FILTER',
    async () => {
      const response: MeasuringBoxesWarningsQueryResponse = await getAlarmVibrationFilter(
        {
          company_id: currentUser?.last_company_id, 
          take: 0, skip: 500,
        }
      )
      const alarmVibrationFilter = response?.data as MeasuringBoxesWarnings[]

      return alarmVibrationFilter
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const {
    isFetching: isFetchingAlarmSoundFilter,
    refetch: refetchAlarmSoundFilter,
    data: alarmSoundFilter,
  } = useQuery(
    'ALARM-SOUND-FILTER',
    async () => {
      const response: MeasuringBoxesWarningsQueryResponse = await getAlarmSoundFilter(
        {
          company_id: currentUser?.last_company_id, 
          take: 0, skip: 500,
        }
      )
      const alarmSoundFilter = response?.data as MeasuringBoxesWarnings[]

      return alarmSoundFilter
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const {
    isFetching: isFetchingAlarmEnergyFilter,
    refetch: refetchAlarmEnergyFilter,
    data: alarmEnergyFilter,
  } = useQuery(
    'ALARM-ENERGY-FILTER',
    async () => {
      const response: MeasuringBoxesWarningsQueryResponse = await getAlarmEnergyFilter(
        {
          company_id: currentUser?.last_company_id, 
          take: 0, skip: 500,
        }
      )
      const alarmEnergyFilter = response?.data as MeasuringBoxesWarnings[]

      return alarmEnergyFilter
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const {
    isFetching: isFetchingAlarmCutCableFilter,
    refetch: refetchAlarmCutCableFilter,
    data: alarmCutCableFilter,
  } = useQuery(
    'ALARM-CUT-CABLE-FILTER',
    async () => {
      const response: MeasuringBoxesWarningsQueryResponse = await getAlarmCutCableFilter(
        {
          company_id: currentUser?.last_company_id, 
          take: 0, skip: 500,
        }
      )
      const alarmCutCableFilter = response?.data as MeasuringBoxesWarnings[]

      return alarmCutCableFilter
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  useEffect(() => {
    timer()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchingSeconds])

  return (
    <>
    <div id="sensors_page" className="d-flex flex-row">

      <div
        id="side_menu"
        className="d-flex flex-column custom-height overflow-scroll w-300px bg-secondary pt-3 pb-3 ps-3 pe-3 rounded"
        >

        <button className={clsx("button-sensor-info", {"border border-2 border-primary": selectedSensor?.id === SensorTypes.request_opening_of_safe.id})}
          onClick={() => {
            setSelectedSensor(SensorTypes.request_opening_of_safe)
          }}>
          <SensorInfo sensor={SensorTypes.request_opening_of_safe} amount={openingRequests?.length || 0} isFetching={isFetchingOpeningRequests} />
        </button>

        <button className={clsx("button-sensor-info", {"border border-2 border-primary": selectedSensor?.id === SensorTypes.door.id})}
          onClick={() => {
            setBoxes([])
            setSelectedSensor(SensorTypes.door)
          }}>
          <SensorInfo sensor={SensorTypes.door} amount={0} />
        </button>

        <button className={clsx("button-sensor-info", {"border border-2 border-primary": selectedSensor?.id === SensorTypes.bolt.id})}
          onClick={() => {
            setBoxes([])
            setSelectedSensor(SensorTypes.bolt)
          }}>
          <SensorInfo sensor={SensorTypes.bolt} amount={0} />
        </button>

        <button className={clsx("button-sensor-info", {"border border-2 border-primary": selectedSensor?.id === SensorTypes.door_lock.id})}
          onClick={() => {
            setBoxes([])
            setSelectedSensor(SensorTypes.door_lock)
          }}>
          <SensorInfo sensor={SensorTypes.door_lock} amount={0} />
        </button>

        <button className={clsx("button-sensor-info", {"border border-2 border-primary": selectedSensor?.id === SensorTypes.vibration.id})}
          onClick={() => {
            setBoxes(alarmVibrationFilter || [])
            setSelectedSensor(SensorTypes.vibration)
          }}>
          <SensorInfo sensor={SensorTypes.vibration} amount={alarmVibrationFilter?.length || 0} isFetching={isFetchingAlarmVibrationFilter} />
        </button>

        <button className={clsx("button-sensor-info", {"border border-2 border-primary": selectedSensor?.id === SensorTypes.energy.id})}
          onClick={() => {
            setBoxes(alarmEnergyFilter || [])
            setSelectedSensor(SensorTypes.energy)
          }
        }>
          <SensorInfo sensor={SensorTypes.energy} amount={alarmEnergyFilter?.length || 0} isFetching={isFetchingAlarmEnergyFilter} />
        </button>

        <button className={clsx("button-sensor-info", {"border border-2 border-primary": selectedSensor?.id === SensorTypes.violation.id})}
          onClick={() => {
            setBoxes(alarmViolationFilter || [])
            setSelectedSensor(SensorTypes.violation)
          }}>
          <SensorInfo sensor={SensorTypes.violation} amount={alarmViolationFilter?.length || 0} isFetching={isFetchingAlarmViolationFilter} />
        </button>

        <button className={clsx("button-sensor-info", {"border border-2 border-primary": selectedSensor?.id === SensorTypes.sound.id})}
          onClick={() => {
            setBoxes(alarmSoundFilter || [])
            setSelectedSensor(SensorTypes.sound)
          }}>
          <SensorInfo sensor={SensorTypes.sound} amount={alarmSoundFilter?.length || 0} isFetching={isFetchingAlarmSoundFilter} />
        </button>

        <button className={clsx("button-sensor-info", {"border border-2 border-primary": selectedSensor?.id === SensorTypes.maximum_time_for_open_door.id})}
          onClick={() => {
            setBoxes(alarmMaximumTimeOpenDoorFilter || [])
            setSelectedSensor(SensorTypes.maximum_time_for_open_door)
          }}>
          <SensorInfo sensor={SensorTypes.maximum_time_for_open_door} amount={alarmMaximumTimeOpenDoorFilter?.length || 0} isFetching={isFetchingAlarmMaximumTimeOpenDoorFilter} />
        </button>

        <button className={clsx("button-sensor-info", {"border border-2 border-primary": selectedSensor?.id === SensorTypes.cable_cut_alarm.id})}
          onClick={() => {
            setBoxes(alarmCutCableFilter || [])
            setSelectedSensor(SensorTypes.cable_cut_alarm)
          }}>
          <SensorInfo sensor={SensorTypes.cable_cut_alarm} amount={alarmCutCableFilter?.length || 0} isFetching={isFetchingAlarmCutCableFilter} />
        </button>
      </div>

      <div id="boxes_area" className="d-flex flex-column w-100 custom-height">
        <div id="header" className="d-flex flex-row ms-1 mb-2 bg-secondary rounded ps-3 pe-3">
          <div className="d-flex flex-column justify-content-center col-sm-9 fs-4 fw-bold">
            <div>
              <i className={selectedSensor?.icon}> </i>{selectedSensor?.description}
            </div>
            {selectedSensor?.id === SensorTypes.request_opening_of_safe.id &&
              <div>
                {!selectedSensor
                ? <><i className="bi-arrow-bar-left"></i> Selecione um tipo sensor da lista para visualizar as caixas</>
                : openingRequests && openingRequests.length > 1
                ? `${openingRequests.length} caixas sendo visualizadas`
                : `${openingRequests && openingRequests.length} caixa sendo visualizada`
                }
              </div>
            }
            {selectedSensor?.id !== SensorTypes.request_opening_of_safe.id &&
              <div>
                {!selectedSensor
                ? <><i className="bi-arrow-bar-left"></i> Selecione um tipo sensor da lista para visualizar as caixas</>
                : boxes.length > 1
                ? `${boxes.length} caixas sendo visualizadas`
                : `${boxes.length} caixa sendo visualizada`
                }
              </div>
            }
          </div>
          <div className="col-sm-2 pt-2 pb-2">
            <input
                className="form-control"
                placeholder='Pesquisar Caixa'
                type='text'
                name='search_measuring_box'
            />
          </div>
          <div className="col-sm-1 d-flex align-items-center justify-content-center gap-1">
            {!isFetchingOpeningRequests
            ? <div className="d-flex align-items-center justify-content-center gap-1">
                <div>
                  <KTSVG path='/media/icons/duotune/general/timer.svg' className='svg-icon-size-(12px) '/>
                </div>
                <div className="fs-3" style={{ fontFamily: "Courier Prime", marginTop: "6px" }}>
                  {`${moment(new Date('2024-01-01 01:00:00')).subtract(-refetchingSeconds, 'seconds').format('mm:ss')}`}
                </div>
              </div>
            : <div className="fs-2">
                <i className="bi-hourglass-split"></i>
              </div>
            }
          </div>
        </div>

        <div id="boxes" className="h-100 bg-secondary rounded ms-1 overflow-scroll">
          <div className="d-flex flex-wrap p-2">
            {selectedSensor && selectedSensor.id === SensorTypes.request_opening_of_safe.id &&
              openingRequests && openingRequests.map((box) => {
                return (
                  <div className="me-2 mb-2">
                    {<MeasuringBoxOpeningRequests openingRequest={box} refetchOpeningRequests={refetchOpeningRequests}/>
                    }
                  </div>
                )
              })
            }
            {selectedSensor
              && selectedSensor.id !== SensorTypes.request_opening_of_safe.id
              && boxes.map((box) => {
              return (
                <div className="me-2 mb-2">
                  {<MeasuringBoxDefault box={box} refetchOpeningRequests={refetchAlarmMaximumTimeOpenDoorFilter}/>}
                </div>
              )
            })}
          </div>
        </div>
      </div>

    </div>
    </>
  )
}

export {Sensors}