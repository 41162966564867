import React from 'react'
import type { SVGProps } from 'react'

export function LedWhite(props: SVGProps<SVGSVGElement>) {
	return (<svg  xmlns="http://www.w3.org/2000/svg" width="0.8em" height="0.8em" viewBox="0 0 128 128" {...props}><circle cx={63.93} cy={64} r={60} fill="#bdbdbd"></circle><circle cx={60.03} cy={63.1} r={56.1} fill="#e0e0e0"></circle><path fill="#fff" d="M23.93 29.7c4.5-7.1 14.1-13 24.1-14.8c2.5-.4 5-.6 7.1.2c1.6.6 2.9 2.1 2 3.8c-.7 1.4-2.6 2-4.1 2.5a44.64 44.64 0 0 0-23 17.4c-2 3-5 11.3-8.7 9.2c-3.9-2.3-3.1-9.5 2.6-18.3"></path></svg>);
}

export function LedOff(props: SVGProps<SVGSVGElement>) {
	return (<svg  xmlns="http://www.w3.org/2000/svg" width="0.8em" height="0.8em" viewBox="0 0 128 128" {...props}><circle cx={63.93} cy={64} r={60} fill="#bdbdbd"></circle><circle cx={60.03} cy={63.1} r={56.1} fill="#808080"></circle><path fill="#fff" d="M23.93 29.7c4.5-7.1 14.1-13 24.1-14.8c2.5-.4 5-.6 7.1.2c1.6.6 2.9 2.1 2 3.8c-.7 1.4-2.6 2-4.1 2.5a44.64 44.64 0 0 0-23 17.4c-2 3-5 11.3-8.7 9.2c-3.9-2.3-3.1-9.5 2.6-18.3"></path></svg>);
}

export function LedGreen(props: SVGProps<SVGSVGElement>) {
	return (<svg xmlns="http://www.w3.org/2000/svg" width="0.8em" height="0.8em" viewBox="0 0 128 128" {...props}><circle cx={63.93} cy={64} r={60} fill="#689f38"></circle><circle cx={60.03} cy={63.1} r={56.1} fill="#5aaf00"></circle><path fill="#aed581" d="M23.93 29.7c4.5-7.1 14.1-13 24.1-14.8c2.5-.4 5-.6 7.1.2c1.6.6 2.9 2.1 2 3.8c-.7 1.4-2.6 2-4.1 2.5a44.64 44.64 0 0 0-23 17.4c-2 3-5 11.3-8.7 9.2c-3.9-2.3-3.1-9.5 2.6-18.3"></path></svg>);
}

export function LedBlue(props: SVGProps<SVGSVGElement>) {
	return (<svg xmlns="http://www.w3.org/2000/svg" width="0.8em" height="0.8em" viewBox="0 0 128 128" {...props}><circle cx={63.93} cy={64} r={60} fill="#0d47a1"></circle><circle cx={60.03} cy={63.1} r={56.1} fill="#007df2"></circle><path fill="#fff" d="M23.93 29.7c4.5-7.1 14.1-13 24.1-14.8c2.5-.4 5-.6 7.1.2c1.6.6 2.9 2.1 2 3.8c-.7 1.4-2.6 2-4.1 2.5a44.64 44.64 0 0 0-23 17.4c-2 3-5 11.3-8.7 9.2c-3.9-2.3-3.1-9.5 2.6-18.3" opacity={0.3}></path></svg>);
}

export function LedYellow(props: SVGProps<SVGSVGElement>) {
	return (<svg xmlns="http://www.w3.org/2000/svg" width="0.8em" height="0.8em" viewBox="0 0 128 128" {...props}><circle cx={63.93} cy={64} r={60} fill="#d69200"></circle><circle cx={60.03} cy={63.1} r={56.1} fill="#f2a600"></circle><path fill="#fff170" d="M23.93 29.7c4.5-7.1 14.1-13 24.1-14.8c2.5-.4 5-.6 7.1.2c1.6.6 2.9 2.1 2 3.8c-.7 1.4-2.6 2-4.1 2.5a44.64 44.64 0 0 0-23 17.4c-2 3-5 11.3-8.7 9.2c-3.9-2.3-3.1-9.5 2.6-18.3"></path></svg>);
}

export function LedRed(props: SVGProps<SVGSVGElement>) {
	return (<svg xmlns="http://www.w3.org/2000/svg" width="0.8em" height="0.8em" viewBox="0 0 128 128" {...props}><circle cx={63.93} cy={64} r={60} fill="#c33"></circle><circle cx={60.03} cy={63.1} r={56.1} fill="#ef2617"></circle><path fill="#fc9b94" d="M23.93 29.7c4.5-7.1 14.1-13 24.1-14.8c2.5-.4 5-.6 7.1.2c1.6.6 2.9 2.1 2 3.8c-.7 1.4-2.6 2-4.1 2.5a44.64 44.64 0 0 0-23 17.4c-2 3-5 11.3-8.7 9.2c-3.9-2.3-3.1-9.5 2.6-18.3"></path></svg>);
}
