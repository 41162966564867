import {FC, useState} from 'react'
import {useFormik} from 'formik'
import {useQuery} from 'react-query'
import {QUERIES} from '../../../_metronic/helpers'
import {initialMeasuringBox, MeasuringBox, MeasuringBoxType, Project, MeasuringBoxModelsList} from '../../modules/apps/measuring-box-management/measuring-box-list/core/_models'
import clsx from 'clsx'
import {getAllProjects} from '../../modules/apps/measuring-box-management/measuring-box-list/core/_requests'
import {useQueryResponse} from '../../modules/apps/measuring-box-management/measuring-box-list/core/QueryResponseProvider'
import {useAuth} from '../../../app/modules/auth'
import { LedGreen, LedRed, LedYellow, LedOff } from '../../../app/components/Leds'
import { SensorTypes } from '../../pages/sensors/core/_models'
import { MeasuringBoxesListLoading } from '../../modules/apps/measuring-box-management/measuring-box-list/components/loading/MeasuringBoxesListLoading'

type Props = {
  isMeasuringBoxLoading: boolean
  response: any
  setShowMeasuringBoxDetailModal: Function
}

const MeasuringBoxDetailModal: FC<Props> = ({response, isMeasuringBoxLoading, setShowMeasuringBoxDetailModal}) => {
  const measuringBox = response.length > 0 ? response[0] : response
  const {refetch} = useQueryResponse()
  const {currentUser} = useAuth()

  const [measuringBoxForEdit] = useState<MeasuringBox>({
    ...measuringBox,
    name: measuringBox.name || initialMeasuringBox.name,
    company_id: measuringBox.company_id || initialMeasuringBox.company_id,
    bluetooth: measuringBox.bluetooth || initialMeasuringBox.bluetooth,
    address: measuringBox.address || initialMeasuringBox.address,
    cmb_number: measuringBox.cmb_number || initialMeasuringBox.cmb_number,
    default_password: measuringBox.default_password || initialMeasuringBox.default_password,
    status_id: measuringBox.status_id || initialMeasuringBox.status_id,
    project_id: measuringBox.project_id || initialMeasuringBox.project_id,
    type: measuringBox.type || initialMeasuringBox.type,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setShowMeasuringBoxDetailModal(undefined)
  }

  const formik = useFormik({
    initialValues: measuringBoxForEdit,
    onSubmit: () => {},
  })

  const {data: projects} = useQuery(
    `${QUERIES.PROJECTS}`,
    () => {
      const companyId = (currentUser?.last_company_id) ? +currentUser.last_company_id : 0;
      return getAllProjects(companyId);
    },
    {
      enabled: !isMeasuringBoxLoading,
      refetchOnWindowFocus: false,
      onError: (err) => {
        console.error(err)
      },
    }
  )

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={undefined} noValidate>
        {/* begin::Scroll */}
        <div
          className='scroll-y ps-1'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
          style={{ overflowX: 'hidden' }}
          >
          <div className='row g-3 mb-3'>
            <div className='col-sm-2'>
              <label className='fw-bold fs-6 mb-2'>Número CMB</label>
              <input
                placeholder='Número CMB'
                {...formik.getFieldProps('cmb_number')}
                type='text'
                name='cmb_number'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                )}
                autoComplete='off'
                readOnly={formik.isSubmitting || isMeasuringBoxLoading}
              />
            </div>
            <div className='col-sm-2'>
              <label className='fw-bold fs-6 mb-2'>Bluetooth</label>
              <input
                placeholder='Bluetooth'
                {...formik.getFieldProps('bluetooth')}
                type='text'
                name='bluetooth'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                )}
                autoComplete='off'
                readOnly
              />
            </div>
            <div className='col-sm-2'>
              <label className='fw-bold fs-6 mb-2'>Senha Padrão</label>
              <input
                placeholder='Senha Padrão'
                {...formik.getFieldProps('default_password')}
                type='text'
                name='default_password'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                )}
                autoComplete='off'
                readOnly
              />
            </div>
            {/* <div className='col-sm-1'></div> */}
            <div className='col-sm-3'>
              <label className='fw-bold fs-6 mb-2'>Tipo de Caixa</label>
              <div className="mt-2 d-flex gap-6">
                <div className="form-check form-check-custom form-check-solid">
                  <input
                    name='type' className="form-check-input" type="radio"
                    id="measuringBoxType"
                    onChange={() => formik.setFieldValue('type', MeasuringBoxType.online)}
                    checked={formik.getFieldProps('type').value === MeasuringBoxType.online}
                    readOnly
                  />
                  <label className="form-check-label" htmlFor="measuringBoxType">
                    Online
                  </label>
                </div>
                <div className="form-check form-check-custom form-check-solid">
                  <input
                    name='type' className="form-check-input" type="radio"
                    id="measuringBoxType"
                    onChange={() => formik.setFieldValue('type', MeasuringBoxType.offline)}
                    checked={formik.getFieldProps('type').value === MeasuringBoxType.offline}
                    readOnly
                  />
                  <label className="form-check-label" htmlFor="measuringBoxType">
                    Offline
                  </label>
                </div>
              </div>
            </div>
            <div className='col-sm-3'>
              <label className='fw-bold fs-6 mb-2 d-flex justify-content-end me-1'>Modelo</label>
              <div className="d-flex justify-content-end">
              <select
                className="form-select form-select-solid fs-6 text-wrap"
                style={{ height: "44px", paddingTop: 0, paddingBottom: 0, lineHeight: "1.2rem" }}
                {...formik.getFieldProps('model_type')}
                name='model_type'
                disabled
              >
                <option disabled>Modelos</option>
                {MeasuringBoxModelsList.map((model) => {
                  return <option value={model.id} key={model.id}>{model.name}</option>
                })}
              </select>
              </div>
            </div>
          </div>

          <div className='row g-3 mb-3'>
            <div className='col-sm-10'>
              <label className='fw-bold fs-6 mb-2'>Projeto</label>
              <select
                className='form-control form-control-solid form-select mb-lg-0'
                {...formik.getFieldProps('project_id')}
                name='project_id'
                disabled
                >
                <option defaultValue="0">
                  Selecione o projeto
                </option>
                {projects &&
                  (projects as Project[]).map((project) => {
                    return <option value={`${project.id}`} key={project.id}>{project.name}</option>
                  })}
              </select>
            </div>
            <div className='col-sm-2'>
              <label className='fw-bold fs-6 mb-2'>Status</label>
              <select
                className='form-control form-control-solid form-select mb-3 mb-lg-0'
                {...formik.getFieldProps('status_id')}
                name='status_id'
                disabled
                >
                <option defaultValue="0">
                  Selecione o status
                </option>
                <option value={'6'}>Ativo</option>
                <option value={'7'}>Inativo</option>
              </select>
            </div>
          </div>

          <div className='fv-row mb-5'>
            <label className='fw-bold fs-6 mb-2'>Endereço</label>
            <input
              placeholder='Endereço'
              {...formik.getFieldProps('address')}
              type='text'
              name='address'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
              )}
              autoComplete='off'
              readOnly
            />
          </div>

          <div className='row g-3 mb-8'>
            <div className='col-sm-3'>
              <label className='fw-bold fs-6 mb-2'>Latitude</label>
              <input
                placeholder='Latitude'
                {...formik.getFieldProps('lat')}
                type='text'
                name='lat'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                )}
                autoComplete='off'
                readOnly
              />
            </div>
            <div className='col-sm-3'>
              <label className='fw-bold fs-6 mb-2'>Longitude</label>
              <input
                placeholder='Longitude'
                {...formik.getFieldProps('long')}
                type='text'
                name='long'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                )}
                autoComplete='off'
                readOnly
              />
            </div>
            <div className='col-sm-6'>
              <label className='fw-bold fs-6 mb-2'>SIM Card</label>
              <input
                placeholder='SIM Card'
                {...formik.getFieldProps('sim_number')}
                type='text'
                name='long'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                )}
                autoComplete='off'
                readOnly
              />
            </div>
          </div>

          <div className='fv-row mb-5'>
            <label className='fw-bold fs-6 mb-1'>AVISOS E ALARMES</label>
            <div className='bg-black p-4 rounded-3'>

              <div className='row mb-1 gap-1 gap-md-0'>
                <div className='col-md-6 col-sm-12'>
                  <Sensor id={1} name={'Solicitação de abertura'}
                    led={formik.getFieldProps(`${Object.keys(SensorTypes.request_opening_of_safe)}`).value === null ? 'LedOff' : formik.getFieldProps(`${Object.keys(SensorTypes.request_opening_of_safe)}`).value ? 'LedYellow' : 'LedOff' }
                    blinking={formik.getFieldProps(`${Object.keys(SensorTypes.request_opening_of_safe)}`).value}
                  />
                </div>
                <div className='col-md-6 col-sm-12'>
                  <Sensor id={2} name={`Alarme de corte de cabo`}
                    led={formik.getFieldProps('alarm_cable_cut_alarm').value === null ? 'LedOff' : formik.getFieldProps('alarm_cable_cut_alarm').value ? 'LedRed' : 'LedGreen' }
                    blinking={formik.getFieldProps('alarm_cable_cut_alarm').value}
                  />
                </div>
              </div>

              <div className='row mb-1 gap-1 gap-md-0'>
                <div className='col-md-6 col-sm-12'>
                  <Sensor id={3} name={`Porta 1`}
                    led={formik.getFieldProps('alarm_door_1').value === null ? 'LedOff' : formik.getFieldProps('alarm_door_1').value ? 'LedRed' : 'LedGreen' }
                    blinking={formik.getFieldProps('alarm_door_1').value}
                  />
                </div>
                <div className='col-md-6 col-sm-12'>
                  <Sensor id={4} name={`Porta 2`}
                    led={formik.getFieldProps('alarm_door_2').value === null ? 'LedOff' : formik.getFieldProps('alarm_door_2').value ? 'LedRed' : 'LedGreen' }
                    blinking={formik.getFieldProps('alarm_door_2').value}
                  />
                </div>
              </div>

              <div className='row mb-1 gap-1 gap-md-0'>
                <div className='col-md-6 col-sm-12'>
                  <Sensor id={5} name={`Porta 3`}
                    led={formik.getFieldProps('alarm_door_3').value === null ? 'LedOff' : formik.getFieldProps('alarm_door_3').value ? 'LedRed' : 'LedGreen' }
                    blinking={formik.getFieldProps('alarm_door_3').value}
                  />
                </div>
                <div className='col-md-6 col-sm-12'>
                  <Sensor id={6} name={`Porta 4`}
                    led={formik.getFieldProps('alarm_door_4').value === null ? 'LedOff' : formik.getFieldProps('alarm_door_4').value ? 'LedRed' : 'LedGreen' }
                    blinking={formik.getFieldProps('alarm_door_4').value}
                  />
                </div>
              </div>

              <div className='row mb-1 gap-1 gap-md-0'>
                <div className='col-md-6 col-sm-12'>
                  <Sensor id={7} name={`Ferrolho 1`}
                    led={formik.getFieldProps('alarm_bolt_1').value === null ? 'LedOff' : formik.getFieldProps('alarm_bolt_1').value ? 'LedRed' : 'LedGreen' }
                    blinking={formik.getFieldProps('alarm_bolt_1').value}
                  />
                </div>
                <div className='col-md-6 col-sm-12'>
                  <Sensor id={8} name={`Ferrolho 2`}
                    led={formik.getFieldProps('alarm_bolt_2').value === null ? 'LedOff' : formik.getFieldProps('alarm_bolt_2').value ? 'LedRed' : 'LedGreen' }
                    blinking={formik.getFieldProps('alarm_bolt_2').value}
                  />
                </div>
              </div>

              <div className='row mb-1 gap-1 gap-md-0'>
                <div className='col-md-6 col-sm-12'>
                  <Sensor id={9} name={`Fechadura 1`}
                    led={formik.getFieldProps('alarm_door_lock_1').value === null ? 'LedOff' : formik.getFieldProps('alarm_door_lock_1').value ? 'LedRed' : 'LedGreen' }
                    blinking={formik.getFieldProps('alarm_door_lock_1').value}
                  />
                </div>
                <div className='col-md-6 col-sm-12'>
                  <Sensor id={10} name={`Fechadura 2`}
                    led={formik.getFieldProps('alarm_door_lock_2').value === null ? 'LedOff' : formik.getFieldProps('alarm_door_lock_2').value ? 'LedRed' : 'LedGreen' }
                    blinking={formik.getFieldProps('alarm_door_lock_2').value}
                  />
                </div>
              </div>

              <div className='row mb-1 gap-1 gap-md-0'>
                <div className='col-md-6 col-sm-12'>
                  <Sensor id={11} name={`Vibração`}
                    led={formik.getFieldProps('alarm_vibration').value === null ? 'LedOff' : formik.getFieldProps('alarm_vibration').value ? 'LedRed' : 'LedGreen' }
                    blinking={formik.getFieldProps('alarm_vibration').value}
                  />
                </div>
                <div className='col-md-6 col-sm-12'>
                  <Sensor id={12} name={`Energia`}
                    led={formik.getFieldProps('alarm_energy').value === null ? 'LedOff' : formik.getFieldProps('alarm_energy').value ? 'LedRed' : 'LedGreen' }
                    blinking={formik.getFieldProps('alarm_energy').value}
                  />
                </div>
              </div>

              <div className='row mb-1 gap-1 gap-md-0'>
                <div className='col-md-6 col-sm-12'>
                  <Sensor id={13} name={`Violação`}
                    led={formik.getFieldProps('alarm_violation').value === null ? 'LedOff' : formik.getFieldProps('alarm_violation').value ? 'LedRed' : 'LedGreen' }
                    blinking={formik.getFieldProps('alarm_violation').value}
                  />
                </div>
                <div className='col-md-6 col-sm-12'>
                  <Sensor id={14} name={`Tempo máximo porta aberta`}
                    led={formik.getFieldProps('alarm_maximum_time_for_open_door').value === null ? 'LedOff' : formik.getFieldProps('alarm_maximum_time_for_open_door').value ? 'LedRed' : 'LedGreen' }
                    blinking={formik.getFieldProps('alarm_maximum_time_for_open_door').value}
                  />
                </div>
              </div>

              <div className='row mb-1 gap-1 gap-md-0'>
                <div className='col-md-6 col-sm-12'>
                  <Sensor id={13} name={`Fogo`}
                    led={formik.getFieldProps('alarm_fire').value === null ? 'LedOff' : formik.getFieldProps('alarm_fire').value ? 'LedRed' : 'LedGreen' }
                    blinking={formik.getFieldProps('alarm_fire').value}
                  />
                </div>
                <div className='col-md-6 col-sm-12'>
                  <Sensor id={14} name={`Abertura manual por bluetooth`}
                    led={formik.getFieldProps('alarm_manual_opening_by_bluetooth').value === null ? 'LedOff' : formik.getFieldProps('alarm_manual_opening_by_bluetooth').value ? 'LedRed' : 'LedGreen' }
                    blinking={formik.getFieldProps('alarm_manual_opening_by_bluetooth').value}
                  />
                </div>
              </div>

              <div className='row mb-1 gap-1 gap-md-0'>
                <div className='col-md-6 col-sm-12'>
                  <Sensor id={13} name={`Som`}
                    led={formik.getFieldProps('alarm_sound').value === null ? 'LedOff' : formik.getFieldProps('alarm_sound').value ? 'LedRed' : 'LedGreen' }
                    blinking={formik.getFieldProps('alarm_sound').value}
                  />
                </div>
                <div className='col-md-6 col-sm-12'>
                  <Sensor id={14} name={`Cofre aberto pelo servidor`}
                    led={formik.getFieldProps('alarm_vault_opened_by_server_request').value === null ? 'LedOff' : formik.getFieldProps('alarm_vault_opened_by_server_request').value ? 'LedRed' : 'LedGreen' }
                    blinking={formik.getFieldProps('alarm_vault_opened_by_server_request').value}
                  />
                </div>
              </div>
            </div>

            <label className='fw-bold fs-4 mt-5'>Parâmetros</label>
            <div className='border border-solid border-gray-700 mb-3 mt-0'></div>

            <div className='row g-3'>
              <div className='col-sm-6'>
                <label className=' fw-bold fs-6 mb-2'>Sensibilidade do Sensor de Vibração</label>
                <input
                  placeholder='0,000'
                  {...formik.getFieldProps('vibration_sensor_sensitivity')}
                  type='text'
                  name='vibration_sensor_sensitivity'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                  )}
                  autoComplete='off'
                  readOnly
                />
              </div>
              <div className='col-sm-6'>
                <label className=' fw-bold fs-6 mb-2'>Sensibilidade do Sensor de Som</label>
                <input
                  placeholder='0,000'
                  {...formik.getFieldProps('sound_sensor_sensitivity')}
                  type='text'
                  name='sound_sensor_sensitivity'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                  )}
                  autoComplete='off'
                  readOnly
                />
              </div>
              <div className='col-sm-6'>
                <label className=' fw-bold fs-6 mb-2'>Nº de Pulsos Admissíveis</label>
                <input
                  placeholder='0'
                  {...formik.getFieldProps('number_of_admissible_pulses')}
                  type='text'
                  name='number_of_admissible_pulses'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                  )}
                  autoComplete='off'
                  readOnly
                />
              </div>
              <div className='col-sm-6'>
                <label className=' fw-bold fs-6 mb-2'>Magnitude Relacional do Sensor</label>
                <input
                  placeholder='0,00000'
                  {...formik.getFieldProps('relational_magnitude')}
                  type='text'
                  name='relational_magnitude'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                  )}
                  autoComplete='off'
                  readOnly
                />
              </div>
              <div className='col-sm-6'>
                <label className=' fw-bold fs-6 mb-2'>Nome de Redundância</label>
                <input
                  placeholder='Nome de Redundância'
                  {...formik.getFieldProps('redundancy_name')}
                  type='text'
                  name='redundancy_name'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                  )}
                  autoComplete='off'
                  readOnly
                />
              </div>
              <div className='col-sm-6'>
                <label className=' fw-bold fs-6 mb-2'>Senha de Redundância</label>
                <input
                  placeholder='Senha de Redundância'
                  {...formik.getFieldProps('redundancy_password')}
                  type='text'
                  name='redundancy_password'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                  )}
                  autoComplete='off'
                  readOnly
                />
              </div>
            </div>
          </div>
        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-3'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-primary me-3'
            data-kt-users-modal-action='cancel'
            >
            Fechar
          </button>

        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isMeasuringBoxLoading) && <MeasuringBoxesListLoading />}

    </>
  )
}

type SensorProps = {
  id: number,
  name: string,
  led: 'LedGreen' | 'LedRed' | 'LedYellow' | 'LedOff',
  blinking?: boolean,
}

const Sensor = ({id, name, led, blinking = false}: SensorProps) => {
  return (
    <div id={`sensor-${id}`} className='fw-bold fs-5 py-1 px-4 rounded' style={{backgroundColor: '#212121', color: '#D6D6D6'}}>
      <div className='row'>
        <div className='col-11'>
          {name}
        </div>
        <div className='col-1'>
          <div className={blinking ? "animation-blink" : ""} style={{height: "17px"}}>
            {
              led === 'LedYellow' ? <LedYellow />
                : led === 'LedOff' ? <LedOff />
                : led === 'LedRed' ? <LedRed />
                : led === 'LedGreen' && <LedGreen />
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export {MeasuringBoxDetailModal}
