import {FC, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {isNotEmpty} from '../../../../../../_metronic/helpers'
import {initialOperator, Company} from '../core/_models'
import clsx from 'clsx'
import {toast} from 'react-toastify'
import {useListView} from '../core/ListViewProvider'
import {OperatorsListLoading} from '../components/loading/OperatorsListLoading'
import {createCompany, updateCompany} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'

type Props = {
  isOperatorLoading: boolean
  response: any
  status: any
}

const editOperatorSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Mínimo 3 caracteres')
    .max(100, 'Maximum 50 caracteres')
    .required('Nome do operador é obrigatório')
})

const EditModalForm: FC<Props> = ({response, isOperatorLoading}) => {
  const operator = response.length > 0 ? response[0] : response
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()

  const [operatorForEdit] = useState<Company>({
    ...operator,
    name: operator.name || initialOperator.name,
    status_id: operator.status_id || initialOperator.status_id,
    show_menu_map: operator.show_menu_map || initialOperator.show_menu_map,
    show_menu_sensors: operator.show_menu_sensors || initialOperator.show_menu_sensors,
    can_open_the_box_manually: operator.can_open_the_box_manually || initialOperator.can_open_the_box_manually,
    link_operators_when_create_measuring_box: operator.link_operators_when_create_measuring_box || initialOperator.link_operators_when_create_measuring_box,
    link_measuring_boxes_when_create_operator: operator.link_measuring_boxes_when_create_operator || initialOperator.link_measuring_boxes_when_create_operator,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: operatorForEdit,
    validationSchema: editOperatorSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          await updateCompany(values)
          toast.success(`Empresa ${values.name} atualizada com sucesso`)
        } else {
          await createCompany(values)
          toast.success(`Empresa ${values.name} criada com sucesso`)
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y ps-1'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Input group */}
          <div className='fv-row mb-7 d-flex'>
            <div className='col-sm-10 ps-0 pe-4'>
              {/* begin::Label */}
              <label className='required fw-bold fs-6 mb-2'>Nome da Empresa</label>
              {/* end::Label */}

              {/* begin::Input */}
              <input
                placeholder='Nome da empresa'
                {...formik.getFieldProps('name')}
                type='text'
                name='name'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.name && formik.errors.name},
                  {
                    'is-valid': formik.touched.name && !formik.errors.name,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting || isOperatorLoading}
              />
              {formik.touched.name && formik.errors.name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.name}</span>
                  </div>
                </div>
              )}
              {/* end::Input */}
            </div>
            <div className='col-sm-2 ps-0'>
              <label className='fw-bold fs-6 mb-2'>Status</label>
              <select
                className='form-control form-control-solid form-select mb-3 mb-lg-0'
                {...formik.getFieldProps('status_id')}
                name='status_id'
                disabled={formik.isSubmitting || isOperatorLoading}
              >
                <option disabled>
                  Selecione o status
                </option>
                <option value={'1'}>Ativo</option>
                <option value={'2'}>Inativo</option>
              </select>
              {formik.touched.status_id && formik.errors.status_id && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{formik.errors.status_id}</span>
                </div>
              )}
            </div>
          </div>
          {/* end::Input group */}

          <div className='row mb-0 m-0 p-0'>
            <div className='col-sm-12 mt-3 ps-0'>
              <div className="mb-2">
                <div className="form-check form-switch form-check-custom form-check-solid justify-content-between">
                    <label className="fw-semibold fs-6 pe-3" htmlFor="flexSwitchDefault">
                      Exibir menu Mapa
                    </label>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      {...formik.getFieldProps('show_menu_map')}
                      name="show_menu_map"
                      checked={formik.getFieldProps('show_menu_map').value}
                      disabled={formik.isSubmitting || isOperatorLoading}
                    />
                </div>
              </div>
              <div className="mb-2">
                <div className="form-check form-switch form-check-custom form-check-solid justify-content-between">
                    <label className="fw-semibold fs-6 pe-3" htmlFor="flexSwitchDefault">
                      Exibir menu Sensores
                    </label>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      {...formik.getFieldProps('show_menu_sensors')}
                      name="show_menu_sensors"
                      checked={formik.getFieldProps('show_menu_sensors').value}
                      disabled={formik.isSubmitting || isOperatorLoading}
                    />
                </div>
              </div>
              <div className="mb-2">
                <div className="form-check form-switch form-check-custom form-check-solid justify-content-between">
                    <label className="fw-semibold fs-6 pe-3" htmlFor="flexSwitchDefault">
                      Caixa pode ser aberta manualmente
                    </label>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      {...formik.getFieldProps('can_open_the_box_manually')}
                      name="can_open_the_box_manually"
                      checked={formik.getFieldProps('can_open_the_box_manually').value}
                      disabled={formik.isSubmitting || isOperatorLoading}
                    />
                </div>
              </div>
            </div>
          </div>
          <div className='row mb-0 m-0 p-0'>
            <div className='col-sm-12 ps-0 d-flex flex-column'>
              <div className="mb-2">
                <div className="form-check form-switch form-check-custom form-check-solid justify-content-between">
                  <label className="fw-semibold fs-6 pe-3" htmlFor="flexSwitchDefault">
                    Ao criar uma caixa devem ser vinculados todos os operadores a nova caixa
                  </label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    {...formik.getFieldProps('link_operators_when_create_measuring_box')}
                    name="link_operators_when_create_measuring_box"
                    checked={formik.getFieldProps('link_operators_when_create_measuring_box').value}
                    disabled={formik.isSubmitting || isOperatorLoading}
                  />
                </div>
              </div>
              <div className="mb-1">
                <div className="form-check form-switch form-check-custom form-check-solid justify-content-between">
                  <label className="fw-semibold fs-6 pe-3" htmlFor="flexSwitchDefault">
                    Ao criar um novo operador devem ser vinculadas todas as caixas ao novo operador
                  </label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    {...formik.getFieldProps('link_measuring_boxes_when_create_operator')}
                    name="link_measuring_boxes_when_create_operator"
                    checked={formik.getFieldProps('link_measuring_boxes_when_create_operator').value}
                    disabled={formik.isSubmitting || isOperatorLoading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-8'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isOperatorLoading}
          >
            Cancelar
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={isOperatorLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Confirmar</span>
            {(formik.isSubmitting || isOperatorLoading) && (
              <span className='indicator-progress'>
                Por favor, aguarde...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isOperatorLoading) && <OperatorsListLoading />}
    </>
  )
}

export {EditModalForm}
