import axios, {AxiosResponse} from 'axios'
import {
  MeasuringBoxesMapsQueryResponse,
} from './_models'

const API_URL = process.env.REACT_APP_API_URL

const getMeasuringBoxesMaps = (query: Record<string, any>): Promise<MeasuringBoxesMapsQueryResponse> => {
  return axios
    .get(`${API_URL}/measuring-box/${query.company_id}/maps`, {params: query})
    .then((d: AxiosResponse<MeasuringBoxesMapsQueryResponse>) => d.data)
}

export {
  getMeasuringBoxesMaps,
}
