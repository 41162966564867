import {ID, Response} from '../../../../../../_metronic/helpers'

export const MeasuringBoxStatus = {
  'active': {id: 6, value: 'active', name: 'Ativo'},
  'inactive': {id: 7, value: 'inactive', name: 'Inativo'},
  'canceled': {id: 14, value: 'canceled', name: 'Cancelado'},
}

export enum MeasuringBoxType {
  'online' = 1,
  'offline' = 2,
}

export enum MeasuringBoxModels {
  'bluetooth_com_barreira' = 1,
  'bluetooth_sem_barreira' = 2,
}

export const MeasuringBoxModelsList = [
  {id: MeasuringBoxModels.bluetooth_com_barreira, name: 'Bluetooth com barreira'},
  {id: MeasuringBoxModels.bluetooth_sem_barreira, name: 'Bluetooth sem barreira'},
]

export type MeasuringBoxAlarms = {
  alarm_door_1: boolean | null;
  alarm_door_2: boolean | null;
  alarm_door_3: boolean | null;
  alarm_door_4: boolean | null;
  alarm_bolt_1: boolean | null;
  alarm_bolt_2: boolean | null;
  alarm_door_lock_1: boolean | null;
  alarm_door_lock_2: boolean | null;
  alarm_vault_opened_by_server_request: boolean | null;
  alarm_fire: boolean | null;
  alarm_vibration: boolean | null;
  alarm_energy: boolean | null;
  alarm_violation: boolean | null;
  alarm_sound: boolean | null;
  alarm_manual_opening_by_bluetooth: boolean | null;
  alarm_maximum_time_for_open_door: boolean | null;
  alarm_cable_cut_alarm: boolean | null;
  request_opening_of_safe?: boolean | null;
}

export type MeasuringBoxAlarmsQueryResponse = Response<Array<MeasuringBoxAlarms>>

export type MeasuringBox = {
  id?: ID
  name: string;
  address: string;
  bluetooth: string;
  model_type?: number;
  cmb_number: string;
  default_password: string;
  status_name?: string;
  status_id: number;
  project_id: number;
  company_id: number;
  type: number
  sim_number: string;
  lat?: number;
  long?: number;
  alarm_door_1: boolean;
  alarm_door_2: boolean;
  alarm_door_3: boolean;
  alarm_door_4: boolean;
  alarm_bolt_1: boolean;
  alarm_bolt_2: boolean;
  alarm_door_lock_1: boolean;
  alarm_door_lock_2: boolean;
  alarm_vault_opened_by_server_request: boolean;
  alarm_fire: boolean;
  alarm_vibration: boolean;
  alarm_energy: boolean;
  alarm_violation: boolean;
  alarm_sound: boolean;
  alarm_manual_opening_by_bluetooth: boolean;
  alarm_maximum_time_for_open_door: boolean;
  alarm_cable_cut_alarm: boolean;
  request_opening_of_safe?: boolean;
  vibration_sensor_sensitivity: number,
  sound_sensor_sensitivity: number,
  number_of_admissible_pulses: number,
  maximum_time_for_open_door: number,
  relational_magnitude: number,
  redundancy_name: string,
  redundancy_password: string,
}

export type Status = {
  id?: ID
  name?: string
}

export type Project = {
  id?: ID
  name?: string
  description: string
}

export type ProjectsQueryResponse = Response<Array<Project>>

export type MeasuringBoxesQueryResponse = Response<Array<MeasuringBox>>

export const initialMeasuringBox: MeasuringBox = {
  address: '',
  name: '',
  bluetooth: '',
  model_type: undefined,
  cmb_number: '',
  default_password: '',
  status_name: '',
  status_id: 6,
  project_id: 0,
  company_id: 0,
  type: MeasuringBoxType.offline,
  sim_number: '',
  alarm_door_1: false,
  alarm_door_2: false,
  alarm_door_3: false,
  alarm_door_4: false,
  alarm_bolt_1: false,
  alarm_bolt_2: true,
  alarm_door_lock_1: false,
  alarm_door_lock_2: false,
  alarm_vault_opened_by_server_request: false,
  alarm_fire: false,
  alarm_vibration: false,
  alarm_energy: false,
  alarm_violation: false,
  alarm_sound: false,
  alarm_manual_opening_by_bluetooth: false,
  alarm_maximum_time_for_open_door: false,
  alarm_cable_cut_alarm: false,
  vibration_sensor_sensitivity: 0,
  sound_sensor_sensitivity: 0,
  number_of_admissible_pulses: 0,
  maximum_time_for_open_door: 0,
  relational_magnitude: 0,
  redundancy_name: '',
  redundancy_password: '',
}

export const initialMeasuringBoxAlarms: MeasuringBoxAlarms = {
  alarm_door_1: null,
  alarm_door_2: null,
  alarm_door_3: null,
  alarm_door_4: null,
  alarm_bolt_1: null,
  alarm_bolt_2: null,
  alarm_door_lock_1: null,
  alarm_door_lock_2: null,
  alarm_vault_opened_by_server_request: null,
  alarm_fire: null,
  alarm_vibration: null,
  alarm_energy: null,
  alarm_violation: null,
  alarm_sound: null,
  alarm_manual_opening_by_bluetooth: null,
  alarm_maximum_time_for_open_door: null,
  alarm_cable_cut_alarm: null,
  request_opening_of_safe: null,
}