import {FC, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useQuery} from 'react-query'
import {isNotEmpty, KTSVG, QUERIES} from '../../../../../../_metronic/helpers'
import {
  initialMeasuringBox,
  MeasuringBox,
  MeasuringBoxType,
  Project,
  MeasuringBoxModelsList,
  MeasuringBoxAlarms,
  initialMeasuringBoxAlarms,
} from '../core/_models'
import clsx from 'clsx'
import {toast} from 'react-toastify'
import {useListView} from '../core/ListViewProvider'
import {MeasuringBoxesListLoading} from '../components/loading/MeasuringBoxesListLoading'
import {
  createMeasuringBoxes,
  updateMeasuringBoxes,
  getAllProjects,
  turnPowerOn,
  turnPowerOff,
  requestOpenDoor,
  getMeasuringBoxAlarmsFilter,
} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {useAuth} from '../../../../auth'
import { LedGreen, LedRed, LedYellow, LedOff } from '../../../../../components/Leds'
import { AlertModal } from '../../../../../components/AlertModal'
import moment from 'moment'

type Props = {
  isMeasuringBoxLoading: boolean
  response: any
}

const editMeasuringBoxSchema = Yup.object().shape({
  bluetooth: Yup.string()
    .matches(/^[0-9]+$/, "Entre somente com números")
    .min(1, 'Mínimo 1 dígito')
    .max(50, 'Máximo 50 dígitos')
    .required('Bluetooth é obrigatório'),
  address: Yup.string()
    .min(3, 'Mínimo 3 caracteres')
    .max(100, 'Máximo 100 caracteres')
    .required('Nome do address é obrigatório'),
  cmb_number: Yup.string()
    .matches(/^[0-9]+$/, "Entre somente com números")
    .min(1, 'Mínimo 1 dígito')
    .max(50, 'Máximo 50 dígitos')
    .required('Número CMB é obrigatório'),
  default_password: Yup.string()
    .matches(/^[0-9]+$/, "Entre somente com números")
    .min(4, 'Mínimo 4 dígitos')
    .max(50, 'Máximo 50 dígitos')
    .required('Senha Padrão é obrigatória'),
  project_id: Yup.number()
    .moreThan(0,'Selecione um projeto')
    .required('Selecione um projeto'),
  status_id: Yup.number()
    .moreThan(0,'Selecione um status')
    .required('Selecione um status'),
  number_of_admissible_pulses: Yup.number()
    .min(0, 'Mínimo 0 pulsos')
    .max(10000, 'Máximo 10.000 pulsos'),
  maximum_time_for_open_door: Yup.number()
    .min(0, 'Mínimo 0 milissegundos')
    .max(28800000, 'Máximo 28800000 milissegundos')
})

const EditModalForm: FC<Props> = ({response, isMeasuringBoxLoading}) => {
  const measuringBox = response.length > 0 ? response[0] : response
  const {setItemIdForUpdate, itemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const {currentUser} = useAuth()
  const [showModalPowerOn, setShowModalPowerOn] = useState(false)
  const [showModalPowerOff, setShowModalPowerOff] = useState(false)
  const [showModalOpenDoor, setShowModalOpenDoor] = useState(false)
  const [alarms, setAlarms] = useState<MeasuringBoxAlarms>()

  const secondsAlarmToRefresh = 10
  const [refetchingAlarmsSeconds, setRefetchingAlarmsSeconds] = useState(secondsAlarmToRefresh)

  const [measuringBoxForEdit] = useState<MeasuringBox>({
    ...measuringBox,
    name: measuringBox.name || initialMeasuringBox.name,
    company_id: measuringBox.company_id || initialMeasuringBox.company_id,
    bluetooth: measuringBox.bluetooth || initialMeasuringBox.bluetooth,
    address: measuringBox.address || initialMeasuringBox.address,
    cmb_number: measuringBox.cmb_number || initialMeasuringBox.cmb_number,
    default_password: measuringBox.default_password || initialMeasuringBox.default_password,
    status_id: measuringBox.status_id || initialMeasuringBox.status_id,
    project_id: measuringBox.project_id || initialMeasuringBox.project_id,
    type: measuringBox.type || initialMeasuringBox.type,
    number_of_admissible_pulses: measuringBox.number_of_admissible_pulses || initialMeasuringBox.number_of_admissible_pulses,
    maximum_time_for_open_door: measuringBox.maximum_time_for_open_door || initialMeasuringBox.maximum_time_for_open_door,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: measuringBoxForEdit,
    validationSchema: editMeasuringBoxSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          await updateMeasuringBoxes(values)
          toast.success(`Caixa ${values.bluetooth} atualizada com sucesso`)
        } else {
          const companyId = (currentUser?.last_company_id) ? +currentUser.last_company_id : 0;
          values.company_id = companyId;
          await createMeasuringBoxes(values)
          toast.success(`Caixa ${values.bluetooth} criada com sucesso`)
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  const companyId = (currentUser?.last_company_id) ? +currentUser.last_company_id : 0;

  const {data: projects } = useQuery(
    `${QUERIES.PROJECTS}`,
    () => {
      return getAllProjects(companyId);
    },
    {
      enabled: !isMeasuringBoxLoading,
      refetchOnWindowFocus: false,
      onError: (err) => {
        console.error(err)
      },
    }
  )

  const {data: alarmsQueryResult, isFetching: isFetchingAlarms, refetch: refetchAlarms} = useQuery(
    'QUERY_ALARMS',
    () => {
      return getMeasuringBoxAlarmsFilter({company_id: companyId, id: formik.getFieldProps('id').value, skip: 1, take: 0})
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onError: (err: any) => {
        toast.error(`Ocorreu um erro ao atualizar o estado dos alarmes: ${err.message}`)
        console.error(err)
      },
    }
  )
 

  const timerRefetchAlarms = () => {
    setTimeout(async () => {
      if (refetchingAlarmsSeconds === 0) {
        await refetchAlarms()
        setAlarms(alarmsQueryResult?.data ? alarmsQueryResult?.data[0] : undefined)
        setRefetchingAlarmsSeconds(secondsAlarmToRefresh)
      } else {
        setRefetchingAlarmsSeconds(refetchingAlarmsSeconds - 1)
      }
    }, 1000)
  }

  useEffect(() => {
    setAlarms(itemIdForUpdate ? response[0] : initialMeasuringBoxAlarms)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response])

  useEffect(() => {
    itemIdForUpdate && timerRefetchAlarms()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchingAlarmsSeconds])

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='scroll-y ps-1'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
          style={{ overflowX: 'hidden' }}
          >
          <div className='row g-3 mb-3'>
            <div className='col-sm-2'>
              <label className='required fw-bold fs-6 mb-2'>Número CMB</label>
              <input
                placeholder='Número CMB'
                {...formik.getFieldProps('cmb_number')}
                type='text'
                name='cmb_number'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.cmb_number && formik.errors.cmb_number},
                  {
                    'is-valid': formik.touched.cmb_number && !formik.errors.cmb_number,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting || isMeasuringBoxLoading}
              />
              {formik.touched.cmb_number && formik.errors.cmb_number && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.cmb_number}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-sm-2'>
              <label className='required fw-bold fs-6 mb-2'>Bluetooth</label>
              <input
                placeholder='Bluetooth'
                {...formik.getFieldProps('bluetooth')}
                type='text'
                name='bluetooth'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.bluetooth && formik.errors.bluetooth},
                  {
                    'is-valid': formik.touched.bluetooth && !formik.errors.bluetooth,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting || isMeasuringBoxLoading}
              />
              {formik.touched.bluetooth && formik.errors.bluetooth && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.bluetooth}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-sm-2'>
              <label className='required fw-bold fs-6 mb-2'>Senha Padrão</label>
              <input
                placeholder='Senha Padrão'
                {...formik.getFieldProps('default_password')}
                type='text'
                name='default_password'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.default_password && formik.errors.default_password},
                  {
                    'is-valid': formik.touched.default_password && !formik.errors.default_password,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting || isMeasuringBoxLoading}
              />
              {formik.touched.default_password && formik.errors.default_password && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.default_password}</span>
                  </div>
                </div>
              )}
            </div>
            {/* <div className='col-sm-1'></div> */}
            <div className='col-sm-3'>
              <label className='required fw-bold fs-6 mb-2'>Tipo de Caixa</label>
              <div className="mt-2 d-flex gap-6">
                <div className="form-check form-check-custom form-check-solid">
                  <input
                    name='type' className="form-check-input" type="radio"
                    id="measuringBoxType"
                    onChange={() => formik.setFieldValue('type', MeasuringBoxType.online)}
                    checked={formik.getFieldProps('type').value === MeasuringBoxType.online}
                  />
                  <label className="form-check-label" htmlFor="measuringBoxType">
                    Online
                  </label>
                </div>
                <div className="form-check form-check-custom form-check-solid">
                  <input
                    name='type' className="form-check-input" type="radio"
                    id="measuringBoxType"
                    onChange={() => formik.setFieldValue('type', MeasuringBoxType.offline)}
                    checked={formik.getFieldProps('type').value === MeasuringBoxType.offline}
                  />
                  <label className="form-check-label" htmlFor="measuringBoxType">
                    Offline
                  </label>
                </div>
              </div>
            </div>
            <div className='col-sm-3'>
              <label className='fw-bold fs-6 mb-2 d-flex justify-content-end me-1'>Modelo</label>
              <div className="d-flex justify-content-end">
              <select
                className="form-select form-select-solid fs-6 text-wrap"
                style={{ height: "44px", paddingTop: 0, paddingBottom: 0, lineHeight: "1.2rem" }}
                {...formik.getFieldProps('model_type')}
                name='model_type'
                disabled={formik.isSubmitting || isMeasuringBoxLoading}
              >
                <option disabled>Modelos</option>
                {MeasuringBoxModelsList.map((model) => {
                  return <option value={model.id} key={model.id}>{model.name}</option>
                })}
              </select>
              {formik.touched.model_type && formik.errors.model_type && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{formik.errors.model_type}</span>
                </div>
              )}
              </div>
            </div>
          </div>

          <div className='row g-3 mb-3'>
            <div className='col-sm-6'>
              <label className='fw-bold fs-6 mb-2'>Projeto</label>
              <select
                className='form-control form-control-solid form-select mb-lg-0'
                {...formik.getFieldProps('project_id')}
                name='project_id'
                disabled={formik.isSubmitting || isMeasuringBoxLoading}
                >
                <option defaultValue="0">
                  Selecione o projeto
                </option>
                {projects &&
                  (projects as Project[]).map((project) => {
                    return <option value={`${project.id}`}>{project.name}</option>
                  })}
              </select>
              {formik.touched.project_id && formik.errors.project_id && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{formik.errors.project_id}</span>
                </div>
              )}
            </div>
            <div className='col-sm-2'>
              <label className='fw-bold fs-6 mb-2 required'>Status Caixa</label>
              <select
                className='form-control form-control-solid form-select mb-3 mb-lg-0'
                {...formik.getFieldProps('status_id')}
                name='status_id'
                disabled={formik.isSubmitting || isMeasuringBoxLoading}
                >
                <option defaultValue="0">
                  Selecione o status
                </option>
                <option value={'6'}>Ativo</option>
                <option value={'7'}>Inativo</option>
              </select>
              {formik.touched.status_id && formik.errors.status_id && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{formik.errors.status_id}</span>
                </div>
              )}
            </div>
            <div className='col-sm-4'>
              <label className='fw-bold fs-6 mb-2 d-flex justify-content-end me-1'>Porta</label>
              <div>
                <button className="btn btn-success btn-md fs-5 w-100" onClick={(event) => {
                  event.preventDefault()
                  setShowModalOpenDoor(true)
                }}>
                  Solicitar Abertura
                </button>
              </div>
            </div>
          </div>

          <div className='fv-row mb-5'>
            <label className='required fw-bold fs-6 mb-2'>Endereço</label>
            <input
              placeholder='Endereço'
              {...formik.getFieldProps('address')}
              type='text'
              name='address'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.address && formik.errors.address},
                {
                  'is-valid': formik.touched.address && !formik.errors.address,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isMeasuringBoxLoading}
            />
            {formik.touched.address && formik.errors.address && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.address}</span>
                </div>
              </div>
            )}
          </div>

          <div className='row g-3 mb-8'>
            <div className='col-sm-3'>
              <label className='fw-bold fs-6 mb-2'>Latitude</label>
              <input
                placeholder='Latitude'
                {...formik.getFieldProps('lat')}
                type='text'
                name='lat'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                )}
                autoComplete='off'
                disabled
              />
            </div>
            <div className='col-sm-3'>
              <label className='fw-bold fs-6 mb-2'>Longitude</label>
              <input
                placeholder='Longitude'
                {...formik.getFieldProps('long')}
                type='text'
                name='long'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                )}
                autoComplete='off'
                disabled
              />
            </div>
            <div className='col-sm-4'>
              <label className='fw-bold fs-6 mb-2'>SIM Card</label>
              <input
                placeholder='SIM Card'
                {...formik.getFieldProps('sim_number')}
                type='text'
                name='long'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0',
                )}
                autoComplete='off'
                disabled
              />
            </div>
            <div className='col-sm-2'>
              <label className='fw-bold fs-6 mb-2 d-flex justify-content-end me-1'>Energia</label>
              <div>
                {!formik.getFieldProps('alarm_energy').value
                  ? <button className="btn btn-danger btn-md fs-5 w-100" onClick={(event) => {
                      event.preventDefault()
                      setShowModalPowerOff(true)
                    }}>
                      Desligar
                    </button>
                  : <button className="btn btn-success btn-md fs-5 w-100" onClick={(event) => {
                    event.preventDefault()
                    setShowModalPowerOn(true)
                  }}>
                    Ligar
                  </button>
                }
              </div>
            </div>
          </div>

          <div className='fv-row mb-5'>
            <div className='d-flex align-items-center'>
              <label className='fw-bold fs-6 mt-1'>AVISOS E ALARMES</label>
              <div className="col-sm-1 d-flex align-items-center justify-content-center">
                {itemIdForUpdate &&
                <div className="d-flex align-items-center justify-content-center gap-1">
                  <div className="fs-3" style={{ fontFamily: "Courier Prime", marginTop: "3px" }}>
                    {!isFetchingAlarms
                    ? <KTSVG path='/media/icons/duotune/general/timer.svg' className='svg-icon-size-(12px)'/> 
                    : <div className='fs-4 mt-1'><i className="bi-hourglass-split"></i></div>}
                  </div>
                  <div className="fs-3" style={{ fontFamily: "Courier Prime", marginTop: "6px" }}>
                    {!isFetchingAlarms ? `${moment(new Date('2024-01-01 01:00:00')).subtract(-refetchingAlarmsSeconds, 'seconds').format('ss')}` : <div>&nbsp;</div>}
                  </div>
                </div>}
              </div>
            </div>
            <div className='bg-black p-4 rounded-3'>

              <div className='row mb-1 gap-1 gap-md-0'>
                <div className='col-md-6 col-sm-12'>
                  <Sensor id={1} name={'Solicitação de abertura'}
                    led={alarms && alarms.request_opening_of_safe === null ? 'LedOff' : alarms && alarms.request_opening_of_safe ? 'LedYellow' : 'LedOff' }
                    blinking={alarms && alarms.request_opening_of_safe !== null && alarms.request_opening_of_safe}
                  />
                </div>
                <div className='col-md-6 col-sm-12'>
                  <Sensor id={2} name={`Alarme de corte de cabo`}
                    led={alarms && alarms.alarm_cable_cut_alarm === null ? 'LedOff' : alarms && alarms.alarm_cable_cut_alarm ? 'LedRed' : 'LedGreen' }
                    blinking={alarms && alarms.alarm_cable_cut_alarm !== null && alarms.alarm_cable_cut_alarm}
                  />
                </div>
              </div>

              <div className='row mb-1 gap-1 gap-md-0'>
                <div className='col-md-6 col-sm-12'>
                  <Sensor id={3} name={`Porta 1`}
                    led={alarms && alarms.alarm_door_1 === null ? 'LedOff' : alarms && alarms.alarm_door_1 ? 'LedRed' : 'LedGreen' }
                    blinking={alarms && alarms.alarm_door_1 !== null && alarms.alarm_door_1}
                  />
                </div>
                <div className='col-md-6 col-sm-12'>
                  <Sensor id={4} name={`Porta 2`}
                    led={alarms && alarms.alarm_door_2 === null ? 'LedOff' : alarms && alarms.alarm_door_2 ? 'LedRed' : 'LedGreen' }
                    blinking={alarms && alarms.alarm_door_2 !== null && alarms.alarm_door_2}
                  />
                </div>
              </div>

              <div className='row mb-1 gap-1 gap-md-0'>
                <div className='col-md-6 col-sm-12'>
                  <Sensor id={5} name={`Porta 3`}
                    led={alarms && alarms.alarm_door_3 === null ? 'LedOff' : alarms && alarms.alarm_door_3 ? 'LedRed' : 'LedGreen' }
                    blinking={alarms && alarms.alarm_door_3 !== null && alarms.alarm_door_3}
                  />
                </div>
                <div className='col-md-6 col-sm-12'>
                  <Sensor id={6} name={`Porta 4`}
                    led={alarms && alarms.alarm_door_4 === null ? 'LedOff' : alarms && alarms.alarm_door_4 ? 'LedRed' : 'LedGreen' }
                    blinking={alarms && alarms.alarm_door_4 !== null && alarms.alarm_door_4}
                  />
                </div>
              </div>

              <div className='row mb-1 gap-1 gap-md-0'>
                <div className='col-md-6 col-sm-12'>
                  <Sensor id={7} name={`Ferrolho 1`}
                    led={alarms && alarms.alarm_bolt_1 === null ? 'LedOff' : alarms && alarms.alarm_bolt_1 ? 'LedRed' : 'LedGreen' }
                    blinking={alarms && alarms.alarm_bolt_1 !== null && alarms.alarm_bolt_1}
                  />
                </div>
                <div className='col-md-6 col-sm-12'>
                  <Sensor id={8} name={`Ferrolho 2`}
                    led={alarms && alarms.alarm_bolt_2 === null ? 'LedOff' : alarms && alarms.alarm_bolt_2 ? 'LedRed' : 'LedGreen' }
                    blinking={alarms && alarms.alarm_bolt_2 !== null && alarms.alarm_bolt_2}
                  />
                </div>
              </div>

              <div className='row mb-1 gap-1 gap-md-0'>
                <div className='col-md-6 col-sm-12'>
                  <Sensor id={9} name={`Fechadura 1`}
                    led={alarms && alarms.alarm_door_lock_1 === null ? 'LedOff' : alarms && alarms.alarm_door_lock_1 ? 'LedRed' : 'LedGreen' }
                    blinking={alarms && alarms.alarm_door_lock_1 !== null && alarms.alarm_door_lock_1}
                  />
                </div>
                <div className='col-md-6 col-sm-12'>
                  <Sensor id={10} name={`Fechadura 2`}
                    led={alarms && alarms.alarm_door_lock_2 === null ? 'LedOff' : alarms && alarms.alarm_door_lock_2 ? 'LedRed' : 'LedGreen' }
                    blinking={alarms && alarms.alarm_door_lock_2 !== null && alarms.alarm_door_lock_2}
                  />
                </div>
              </div>

              <div className='row mb-1 gap-1 gap-md-0'>
                <div className='col-md-6 col-sm-12'>
                  <Sensor id={11} name={`Vibração`}
                    led={alarms && alarms.alarm_vibration === null ? 'LedOff' : alarms && alarms.alarm_vibration ? 'LedRed' : 'LedGreen' }
                    blinking={alarms && alarms.alarm_vibration !== null && alarms.alarm_vibration}
                  />
                </div>
                <div className='col-md-6 col-sm-12'>
                  <Sensor id={12} name={`Energia`}
                    led={alarms && alarms.alarm_energy === null ? 'LedOff' : alarms && alarms.alarm_energy ? 'LedRed' : 'LedGreen' }
                    blinking={alarms && alarms.alarm_energy !== null && alarms.alarm_energy}
                  />
                </div>
              </div>

              <div className='row mb-1 gap-1 gap-md-0'>
                <div className='col-md-6 col-sm-12'>
                  <Sensor id={13} name={`Violação`}
                    led={alarms && alarms.alarm_violation === null ? 'LedOff' : alarms && alarms.alarm_violation ? 'LedRed' : 'LedGreen' }
                    blinking={alarms && alarms.alarm_violation !== null && alarms.alarm_violation}
                  />
                </div>
                <div className='col-md-6 col-sm-12'>
                  <Sensor id={14} name={`Tempo máximo porta aberta`}
                    led={alarms && alarms.alarm_maximum_time_for_open_door === null ? 'LedOff' : alarms && alarms.alarm_maximum_time_for_open_door ? 'LedRed' : 'LedGreen' }
                    blinking={alarms && alarms.alarm_maximum_time_for_open_door !== null && alarms.alarm_maximum_time_for_open_door}
                  />
                </div>
              </div>

              <div className='row mb-1 gap-1 gap-md-0'>
                <div className='col-md-6 col-sm-12'>
                  <Sensor id={13} name={`Fogo`}
                    led={alarms && alarms.alarm_fire === null ? 'LedOff' : alarms && alarms.alarm_fire ? 'LedRed' : 'LedGreen' }
                    blinking={alarms && alarms.alarm_fire !== null && alarms.alarm_fire}
                  />
                </div>
                <div className='col-md-6 col-sm-12'>
                  <Sensor id={14} name={`Abertura manual por bluetooth`}
                    led={alarms && alarms.alarm_manual_opening_by_bluetooth === null ? 'LedOff' : alarms && alarms.alarm_manual_opening_by_bluetooth ? 'LedRed' : 'LedGreen' }
                    blinking={alarms && alarms.alarm_manual_opening_by_bluetooth !== null && alarms.alarm_manual_opening_by_bluetooth}
                  />
                </div>
              </div>

              <div className='row mb-1 gap-1 gap-md-0'>
                <div className='col-md-6 col-sm-12'>
                  <Sensor id={13} name={`Som`}
                    led={alarms && alarms.alarm_sound === null ? 'LedOff' : alarms && alarms.alarm_sound ? 'LedRed' : 'LedGreen' }
                    blinking={alarms && alarms.alarm_sound !== null && alarms.alarm_sound}
                  />
                </div>
                <div className='col-md-6 col-sm-12'>
                  <Sensor id={14} name={`Cofre aberto pelo servidor`}
                    led={alarms && alarms.alarm_vault_opened_by_server_request === null ? 'LedOff' : alarms && alarms.alarm_vault_opened_by_server_request ? 'LedRed' : 'LedGreen' }
                    blinking={alarms && alarms.alarm_vault_opened_by_server_request !== null && alarms.alarm_vault_opened_by_server_request}
                  />
                </div>
              </div>
            </div>

            <label className='fw-bold fs-4 mt-5'>Ajustes</label>
            <div className='border border-solid border-gray-700 mb-3 mt-0'></div>

            <div className='row g-3'>
              <div className='col-sm-6'>
                <label className='fw-bold fs-6 mb-2' htmlFor="range_vibration_sensor_sensitivity">
                  Sensibilidade do Sensor de Vibração - {formik.getFieldProps('vibration_sensor_sensitivity').value || 0}%
                </label>
                <input
                  type="range"
                  className="form-range" 
                  id="range_vibration_sensor_sensitivity"
                  {...formik.getFieldProps('vibration_sensor_sensitivity')}
                  defaultValue={0}
                  name='vibration_sensor_sensitivity'
                  disabled={formik.isSubmitting || isMeasuringBoxLoading}
                />
              </div>
              <div className='col-sm-6'>
                <label className='fw-bold fs-6 mb-2' htmlFor="range_sound_sensor_sensitivity">
                  Sensibilidade do Sensor de Som - {formik.getFieldProps('sound_sensor_sensitivity').value || 0} seg.
                </label>
                <input
                  type="range"
                  min="0"
                  max="3600"
                  defaultValue={0}
                  className="form-range" 
                  id="range_sound_sensor_sensitivity"
                  {...formik.getFieldProps('sound_sensor_sensitivity')}
                  name='sound_sensor_sensitivity'
                  disabled={formik.isSubmitting || isMeasuringBoxLoading}
                />
              </div>
              <div className='col-sm-6'>
                <label className='required fw-bold fs-6 mb-2'>Nº de Pulsos Admissíveis</label>
                <input
                  placeholder='0'
                  {...formik.getFieldProps('number_of_admissible_pulses')}
                  type='text'
                  name='number_of_admissible_pulses'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    {'is-invalid': formik.touched.number_of_admissible_pulses && formik.errors.number_of_admissible_pulses},
                    {
                      'is-valid': formik.touched.number_of_admissible_pulses && !formik.errors.number_of_admissible_pulses,
                    }
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isMeasuringBoxLoading}
                />
                {formik.touched.number_of_admissible_pulses && formik.errors.number_of_admissible_pulses && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.number_of_admissible_pulses}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='col-sm-6'>
                <label className='required fw-bold fs-6 mb-2'>Tempo Máximo de Portas Abertas (ms)</label>
                <input
                  placeholder='Tempo em milissegundos'
                  {...formik.getFieldProps('maximum_time_for_open_door')}
                  type='text'
                  name='maximum_time_for_open_door'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    {'is-invalid': formik.touched.maximum_time_for_open_door && formik.errors.maximum_time_for_open_door},
                    {
                      'is-valid': formik.touched.maximum_time_for_open_door && !formik.errors.maximum_time_for_open_door,
                    }
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isMeasuringBoxLoading}
                />
                {formik.touched.maximum_time_for_open_door && formik.errors.maximum_time_for_open_door && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.maximum_time_for_open_door}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='col-sm-6'>
                <label className='required fw-bold fs-6 mb-2'>Nome de Redundância</label>
                <input
                  placeholder='Nome de Redundância'
                  {...formik.getFieldProps('redundancy_name')}
                  type='text'
                  name='redundancy_name'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    {'is-invalid': formik.touched.redundancy_name && formik.errors.redundancy_name},
                    {
                      'is-valid': formik.touched.redundancy_name && !formik.errors.redundancy_name,
                    }
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isMeasuringBoxLoading}
                />
              </div>
              <div className='col-sm-6'>
                <label className='required fw-bold fs-6 mb-2'>Senha de Redundância</label>
                <input
                  placeholder='Senha de Redundância'
                  {...formik.getFieldProps('redundancy_password')}
                  type='text'
                  name='redundancy_password'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    {'is-invalid': formik.touched.redundancy_password && formik.errors.redundancy_password},
                    {
                      'is-valid': formik.touched.redundancy_password && !formik.errors.redundancy_password,
                    }
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isMeasuringBoxLoading}
                />
              </div>
            </div>
          </div>
        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-3'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isMeasuringBoxLoading}
            >
            Cancelar
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={isMeasuringBoxLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
            >
            <span className='indicator-label'>Confirmar</span>
            {(formik.isSubmitting || isMeasuringBoxLoading) && (
              <span className='indicator-progress'>
                Por favor, aguarde...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isMeasuringBoxLoading) && <MeasuringBoxesListLoading />}

      <AlertModal
        id='alert-power-on'
        show={showModalPowerOn}
        title='Solicitação para Ligar Energia'
        key={'showModalPowerOn'}
        buttonConfirm={{title: 'Confirmar', fnCallback: () => {
          turnPowerOn(formik.getFieldProps('id').value).then((data) => {
            if (data?.success) {
              toast.success('Solicitação para Ligar Energia enviada com sucesso!')
              setShowModalPowerOn(false)
            } else {
              toast.error('Ocorreu um erro no envio da solicitação para ligar energia')
            }
          })
        }}}
        buttonCancel={{title: 'Cancelar', fnCallback: () => setShowModalPowerOn(false)}}
      >
        {`Confirma a solicitação de Ligar Energia para a caixa CMB nº ${formik.getFieldProps('cmb_number').value}?`}
      </AlertModal>

      <AlertModal
        id='alert-power-off'
        show={showModalPowerOff}
        title='Solicitação para Desligar Energia'
        key={'showModalPowerOff'}
        buttonConfirm={{title: 'Confirmar', fnCallback: () => {
          turnPowerOff(formik.getFieldProps('id').value).then((data) => {
            if (data?.success) {
              toast.success('Solicitação para Desligar Energia enviada com sucesso!')
              setShowModalPowerOff(false)
            } else {
              toast.error('Ocorreu um erro no envio da solicitação para desligar energia')
            }
          })
        }}}
        buttonCancel={{title: 'Cancelar', fnCallback: () => setShowModalPowerOff(false)}}
      >
        {`Confirma a solicitação de Desligar Energia para a caixa CMB nº ${formik.getFieldProps('cmb_number').value}?`}
      </AlertModal>

      <AlertModal
        id='alert-open-door'
        show={showModalOpenDoor}
        title='Solicitação de Abertura de Porta'
        key={'showModalOpenDoor'}
        buttonConfirm={{title: 'Confirmar', fnCallback: () => {
          requestOpenDoor(formik.getFieldProps('id').value).then((data) => {
            if (data?.success) {
              toast.success('Solicitação de Abertura de Porta enviada com sucesso!')
              setShowModalOpenDoor(false)
            } else {
              toast.error('Ocorreu um erro no envio da solicitação de abertura de porta')
            }
          })
        }}}
        buttonCancel={{title: 'Cancelar', fnCallback: () => setShowModalOpenDoor(false)}}
      >
        {`Confirma a solicitação de Abertura de Porta para a caixa CMB nº ${formik.getFieldProps('cmb_number').value}?`}
      </AlertModal>
    </>
  )
}

type SensorProps = {
  id: number,
  name: string,
  led: 'LedGreen' | 'LedRed' | 'LedYellow' | 'LedOff',
  blinking?: boolean,
}

const Sensor = ({id, name, led, blinking = false}: SensorProps) => {
  return (
    <div id={`sensor-${id}`} className='fw-bold fs-5 py-1 px-4 rounded' style={{backgroundColor: '#212121', color: '#D6D6D6'}}>
      <div className='row'>
        <div className='col-11'>
          {name}
        </div>
        <div className='col-1'>
          <div className={blinking ? "animation-blink" : ""} style={{height: "17px"}}>
            {
              led === 'LedYellow' ? <LedYellow />
                : led === 'LedOff' ? <LedOff />
                : led === 'LedRed' ? <LedRed />
                : led === 'LedGreen' && <LedGreen />
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export {EditModalForm}
