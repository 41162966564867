import {FC} from 'react'
import {MeasuringBox, MeasuringBoxType} from '../../core/_models'

type Props = {
  box: MeasuringBox
}

const MeasuringBoxIdCell: FC<Props> = ({box}) => {
  return (
    <>
      {
        <div className='d-flex align-items-center'>
          {box.type === MeasuringBoxType.online &&
            <span className='bullet bullet-dot bg-success h-6px w-6px me-2 animation-blink' />
          }
          <div className='d-flex flex-column'>
            {box.id}
          </div>
      </div>
      }
    </>
  )
}

export {MeasuringBoxIdCell}
