import {ReactNode, useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'
import {useMenuInnerContext} from './MenuInnerContext'
import moment from 'moment'
import {KTSVG} from '../../../../helpers'

const headerTitle: Record<string, ReactNode> = {
  '/dashboard': 'Dashboard',
  '/map': 'Mapa',
  '/sensors': 'Sensores',
  '/apps/companies-management/companies': 'Empresas',
  '/apps/measuring-box-management/measuring-box': 'Caixas',
  '/apps/projects-management/projects': 'Projetos',
  '/apps/operators-management/operators': 'Operadores',
  '/apps/indicators-management/indicators': 'Coordenadores',
  '/apps/managers-management/documents': 'Gerentes',
  '/apps/administrators-management/administrators': 'Administradores',
  '/apps/logs-management/logs': 'Históricos',
}

export function MenuInner() {
  const location = useLocation()
  const {
    dashboardStartDate, setDashboardStartDate,
    dashboardEndDate, setDashboardEndDate,
    today,
    isFetchingList,
    resetFetchingList,
    fullScreen,
    setFullScreen,
  } = useMenuInnerContext()

  const toggleFullScreen = () => {
    setFullScreen(!fullScreen)
  }

  function HandleDashboardParameters() {
    const [startDate, setStartDate] = useState(dashboardStartDate)
    const [startDateValid, setStartDateValid] = useState(true)
    const [endDate, setEndDate] = useState(dashboardEndDate)
    const [endDateValid, setEndDateValid] = useState(true)
    const [isDisabled, setIsDisabled] = useState(true)
    const [allGraphicsFetched, setAllGraphicsFetched] = useState(false)

    const handleDates = () => {
      resetFetchingList()
      setDashboardStartDate(startDate)
      setDashboardEndDate(endDate)
    }

    useEffect(() => {
      const startDateCheck = moment(startDate, 'YYYY-MM-DD', true).isValid() && moment(startDate, 'YYYY-MM-DD', true).isSameOrBefore(moment(today, 'YYYY-MM-DD', true))
      setStartDateValid(startDateCheck)

      const endDateCheck = moment(endDate, 'YYYY-MM-DD', true).isValid() && !moment(endDate, 'YYYY-MM-DD', true).isAfter(moment(today, 'YYYY-MM-DD', true)) && moment(endDate, 'YYYY-MM-DD', true).isSameOrAfter(moment(startDate, 'YYYY-MM-DD', true))
      setEndDateValid(endDateCheck)

      if (startDateCheck && endDateCheck) {
        if (dashboardStartDate !== startDate || dashboardEndDate !== endDate)
          setIsDisabled(false)
      } else {
        setIsDisabled(true)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate, endDate])

    useEffect(() => {
      if (dashboardStartDate !== startDate || dashboardEndDate !== endDate)
        setIsDisabled(false)
      else setIsDisabled(true)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dashboardStartDate, dashboardEndDate])

    useEffect(() => {
      const keys = Object.keys(isFetchingList)
      if (keys.length > 0) {
        if (keys.find((key) => isFetchingList[key])) {
          setAllGraphicsFetched(false)
          return
        }
      }
      setAllGraphicsFetched(true)

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetchingList])

    return (
      <div>
        <div className='d-flex align-items-center'>
          <h2 className='mb-0 mt-2 me-4'>
            Dashboard
          </h2>
          <div className="card mt-4 ps-4 bs-4" style={{ border: '1px solid', borderColor: '#BBBAAA'}}>
            <div className='fs-5' style={{marginTop: -10}}>
              <span className='bg-white px-2'>Período:</span>
            </div>
            <div className="card-body fs-6 d-flex py-2 px-0">
              <div className='m-2 ms-0'>
                de:
              </div>
              <input
                style={{ border: '1px solid', borderColor: startDateValid ? '#999999' : '#FF0000'}}
                className='fs-7 fw-semibold rounded p-1'
                type="date"
                id="dash-start-date"
                name="dashboard-start-date"
                lang="pt-BR"
                value={startDate}
                max={today}
                onChange={(event) => setStartDate(event.target.value)}
              />
              <div className='m-2'>
                até:
              </div>
              <input
                style={{ border: '1px solid', borderColor: endDateValid ? '#999999' : '#FF0000'}}
                className='fs-7 fw-semibold rounded p-1'
                type="date"
                id="dash-end-date"
                name="dashboard-end-date"
                lang="pt-BR"
                value={endDate}
                min={startDate}
                max={today}
                onChange={(event) => setEndDate(event.target.value)}
              />
              <button className='btn btn-flex btn-primary mx-4 fs-6 fw-semibold p-4' style={{height: '30px'}}
                onClick={handleDates}
                disabled={isDisabled}
              >
                {allGraphicsFetched
                ? 'Aplicar'
                : <>Aguarde<span className='spinner-border spinner-border-sm align-middle ms-2'></span></>
                }
              </button>
            </div>
          </div>

          <button
            type='button'
            className='btn btn-light-primary ms-4 mt-4 p-0'
            style={{ border: '1px solid', borderColor: '#999999', borderRadius: '8px' }}
            onClick={toggleFullScreen}
            disabled={!allGraphicsFetched}
          >
            <div className='d-flex justify-content-center p-2'>
              <div className='fs-7 fw-bold pe-2' style={{ textAlign: 'start' }}>
                {fullScreen ? 'Reduzir' : 'Ampliar'}<br />
                Gráficos
              </div>
              <div className='d-flex align-items-center'>
                <KTSVG
                  path={fullScreen ? '/media/icons/duotune/general/zoom_minus.svg' : '/media/icons/duotune/general/zoom_plus.svg'}
                  className='svg-icon-1 m-0 p-0'
                />
              </div>
            </div>
          </button>
        </div>
      </div>
    )
  }

  return (
    <div className='d-flex align-items-center'>
      {location.pathname === '/dashboard' ? (
          <h2><HandleDashboardParameters /></h2>
        ) : (
          <h2>{headerTitle[location.pathname]}</h2>
      )}
    </div>
  )
}
